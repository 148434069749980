import { useState, useRef, ReactElement, useEffect } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import DashboardLayout from "../../layouts/Dashboard";
import {
  Paper,
  Typography,
  Divider as MuiDivider,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import InfoCard from "./Info";
const Divider = styled(MuiDivider)(spacing);
interface pdfItem {
  date: string;
  link: string;
}

function PDFSummrization() {
  const { user } = useAuth0();
  const [file, setFile] = useState<File | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const [userText, setUserText] = useState("");
  const [summary, setSummary] = useState("");
  const [fundID, setfundID] = useState("");
  const [fundIDs, setfundIDs] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const [fund_name, setFund_name] = useState("");
  const [fundKeys, setFundKeys] = useState<{ [key: string]: any }>({});
  const [pdfArray, setPdfArray] = useState<pdfItem[]>([]);
  // const [isFileExist, setIsFileExist] = useState(false);
  const [fileURL, setFileURL] = useState("");

  const quarters = [
    { name: "Q1", value: "31-03" },
    { name: "Q2", value: "30-06" },
    { name: "Q3", value: "30-09" },
    { name: "Q4", value: "31-12" },
  ];
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const resetData = () => {
    setFile(null);
    setSummary("");
    setUserText("");
  };
  const resetData2 = () => {
    setSummary("");
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const runscript = async () => {
    if (file && userEmail) {
      resetData2();
      // toast.info("Preparing analysis...");
      setSummary("Preparing analysis...");
      const formData = new FormData();
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      // fileURL === ""
      //   ?
      //   : formData.append("fileURL", fileURL);
      formData.append("pdf", file);
      formData.append("email", userEmail);
      formData.append("text", userText);
      toast.info(
        "AI processing is currently underway...\nThe summary would appear on this page in some time.\nAlternatively, it will also be sent via email."
      );
      try {
        const response = await axiosInstance.post(
          `AI-script-analysis?timestamp=${time}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSummary(response.data);
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error("Please select a file and enter an email address");
    }
  };
  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years.reverse();
  };
  const handleQuarterChange = (event: any) => {
    const quarter = event.target.value;
    setSelectedQuarter(quarter);
    // if (selectedYear !== "") {
    //   const fileExists = pdfArray.find(
    //     (pdf: pdfItem) => pdf.date === `${quarter}-${selectedYear}`
    //   );
    //   if (fileExists) {
    //     setIsFileExist(true);
    //     setFileURL(fileExists.link);
    //     toast.info("File already present - please click Process to proceed");
    //   } else {
    //     setIsFileExist(false);
    //     setFileURL("");
    //   }
    // }
  };

  const handleYearChange = (event: any) => {
    const year = event.target.value;
    setSelectedYear(year);
    // if (selectedQuarter !== "") {
    //   const fileExists = pdfArray.find(
    //     (pdf: pdfItem) => pdf.date === `${selectedQuarter}-${year}`
    //   );
    //   if (fileExists) {
    //     setIsFileExist(true);
    //     setFileURL(fileExists.link);
    //     toast.info("File already present - please click Process to proceed");
    //   } else {
    //     setIsFileExist(false);
    //     setFileURL("");
    //   }
    // }
  };

  const handleFundChange = (event: any) => {
    setFund_name(fundKeys[event.target.value]);
    setfundID(event.target.value);
    setSelectedQuarter("");
    setSelectedYear("");

    getFundstaticData(event.target.value);
  };
  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names");
    const responseData = response.data.data;
    const sortedData = responseData.sort(
      (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
        return a.Fund_Name.toLowerCase().localeCompare(
          b.Fund_Name.toLowerCase()
        );
      }
    );
    setfundIDs(sortedData);
    setFund_name(sortedData[0]["Fund_Name"]);
    setfundID(sortedData[0]["fund_ID"]);
    getFundstaticData(sortedData[0]["fund_ID"]);
    let keys: { [key: string]: any } = {};
    sortedData.map((fund: { [x: string]: any }) => {
      keys[fund["fund_ID"]] = fund["Fund_Name"];
    });
    setFundKeys(keys);
  };

  const getFundstaticData = async (fundID: string) => {
    const response = await axiosInstance.get("/fund-static/" + fundID);
    if (response.data.data && response.data.data[0]) {
      const pdfArray = response.data.data[0]["pdf_link"];
      setPdfArray(pdfArray);
    }
  };
  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    getFundNames();
  }, []);

  return (
    <Paper style={{ width: "100%", paddingTop: "40px" }}>
      <Helmet title="Quarterly Updates" />

      <Typography
        variant="h3"
        style={{
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
        }}
      >
        PDF Summarization
      </Typography>
      <Divider my={6} />
      <InfoCard
        purpose="This tool analyzes the provided PDFs and generates a summary of the content."
        input="Quarterly Report PDF, Reporting Date (Year and Quarter), and Command as text."
        output="You will receive an email with the summarized information once the extraction process is completed."
      />
      {/* 
      <Typography
        style={{
          textAlign: "justify",
          marginBottom: "20px",
          width: "80%",
        }}
      >
        This tool analyzes the provided pdfs and generates a summary of the
        content.
      </Typography> */}

      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fundID}
                  style={{ maxWidth: "300px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundIDs.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select reporting quarter and year:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      label="Quarter"
                    >
                      {quarters.map((quarter) => (
                        <MenuItem key={quarter.name} value={quarter.value}>
                          {quarter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Year Selector */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300, // Adjust max height to your preference
                          },
                        },
                      }}
                      defaultValue={selectedYear}
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div
            style={{
              marginTop: "20px",
              border: "2px dashed #007BFF",
              padding: "30px",
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
            }}
            onDragOver={(event) => event.preventDefault()}
            onDrop={onDrop}
            onClick={() => inputFileRef.current?.click()}
          >
            <Typography variant="body1" style={{ color: "#007BFF" }}>
              {file ? file.name : "Drag & Drop or Click to Select a File"}
            </Typography>
            <input
              accept="application/pdf"
              type="file"
              style={{ display: "none" }}
              onChange={onFileChange}
              onClick={(e: any) => (e.target.value = "")}
              ref={inputFileRef}
            />
          </div>

          <div
            style={{
              width: "100%",
              marginTop: "20px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <TextField
              multiline
              minRows={4}
              maxRows={6}
              fullWidth
              value={userText}
              onChange={(e) => setUserText(e.target.value)}
              type="Text"
              label="Text"
              variant="outlined"
              placeholder="Enter the text to be summarized"
            />
            <TextField
              style={{ marginTop: "20px" }}
              fullWidth
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              type="email"
              label="Email Address"
              variant="outlined"
              placeholder="email@example.com"
            />
            <button
              onClick={runscript}
              style={{
                marginTop: "20px",
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "20px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                outline: "none",
              }}
            >
              Process
            </button>
          </div>
        </div>
      </div>
      {summary && (
        <>
          <div
            style={{
              marginTop: "40px",
              backgroundColor: "#f9f9f9", // Light background color for the box
              padding: "2rem", // Increased padding for better spacing
              borderRadius: "8px", // Rounded corners
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
              textAlign: "justify",
              lineHeight: "1.6", // Better line height for readability
              color: "#333", // Darker text color
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: "1rem", color: "#333" }}
            >
              PDF Summarization Summary
            </Typography>
            <Typography variant="subtitle2" sx={{ marginBottom: "1rem" }}>
              <div dangerouslySetInnerHTML={{ __html: summary }} />
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <button
              onClick={resetData}
              style={{
                backgroundColor: "#D32F2F",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                outline: "none",
              }}
            >
              Reset
            </button>
          </div>
        </>
      )}
    </Paper>
  );
}

PDFSummrization.getLayout = function getLayout(page: ReactElement) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default PDFSummrization;
