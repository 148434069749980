import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Grid,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  TableSortLabel,
  Pagination,
  Chip,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { CheckCircle, HourglassEmpty } from "@mui/icons-material";
import axiosInstance from "../../../utils/axios/axios.instance";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface IRunStates {
  run_ID: string;
  tool_name: string;
  user: string;
  file_name: string;
  md5: string;
  start_time: string;
  run_time: string;
  status: string;
  run_tokens: number;
  run_progress: string;
  fund_ID: string;
  Reporting_Date: string;
}

const RunStatics: React.FC = () => {
  const [runData, setRunData] = useState<IRunStates[]>([]);
  const [filter, setFilter] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState<string>("All");
  const rowsPerPage = 9;

  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilter(event.target.value);
      setPage(1);
    },
    []
  );

  const handleSort = useCallback(() => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  }, []);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    []
  );

  const filteredData = useMemo(() => {
    return runData
      .filter((data) => {
        const matchesRunID = data.run_ID
          .toLowerCase()
          .includes(filter.toLowerCase());
        const matchesStatus =
          statusFilter === "All" || data.status === statusFilter;
        return matchesRunID && matchesStatus;
      })
      .sort((a, b) => {
        const dateA = new Date(a.start_time);
        const dateB = new Date(b.start_time);
        return sortOrder === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      });
  }, [runData, filter, sortOrder, statusFilter]);

  const paginatedData = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    return filteredData.slice(start, start + rowsPerPage);
  }, [filteredData, page, rowsPerPage]);

  const fetchRunData = useCallback(async () => {
    try {
      const response = await axiosInstance.get("run-states");
      setRunData(response.data.data);
    } catch (error) {
      console.error("Error fetching run data:", error);
    }
  }, []);

  const getToolPageRoute = (
    toolName: string,
    runID: string,
    fund_ID: string,
    Reporting_Date: string
  ) => {
    switch (toolName) {
      case "processCapitalCall_v1.0":
        return `/AI_CapitalCall_Update?fundID=${fund_ID}&reportingDate=${Reporting_Date}&runID=${runID}`;
      case "processDistributions_v1.0":
        return `/AI_DistributionCall_Update?fundID=${fund_ID}&reportingDate=${Reporting_Date}&runID=${runID}`;
      case "processQuarterlyReport_v1.0":
        return `/update_script_data?fundID=${fund_ID}&reportingDate=${Reporting_Date}&runID=${runID}`;
      default:
        return `/`; // fallback route
    }
  };

  useEffect(() => {
    fetchRunData();
  }, [fetchRunData]);

  return (
    <>
      <Helmet title="Run Statistics" />
      <Typography variant="h3" gutterBottom display="inline">
        Run Statistics
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Run Statistics</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Paper
        style={{
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            padding: 3,
            borderRadius: 1,
            boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <TextField
                label="Filter by Run ID"
                variant="outlined"
                value={filter}
                onChange={handleFilterChange}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                value={statusFilter}
                onChange={(event) => setStatusFilter(event.target.value)}
                displayEmpty
                fullWidth
                inputProps={{ "aria-label": "Select Status" }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 1,
                  height: "100%",
                }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="In-progress">In Progress</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              style={{
                marginTop: "20px",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell align="center">
                      <TableSortLabel
                        active={true}
                        direction={sortOrder}
                        onClick={handleSort}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          Run ID
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2" fontWeight="bold">
                        Tool Name
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2" fontWeight="bold">
                        User
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2" fontWeight="bold">
                        File Name
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2" fontWeight="bold">
                        Start Time
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2" fontWeight="bold">
                        Run Time
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2" fontWeight="bold">
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2" fontWeight="bold">
                        Run Progress
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData.map((row, index) => (
                    <TableRow
                      key={row.run_ID}
                      sx={{
                        backgroundColor: (theme) =>
                          index % 2 === 0 ? "#FFFFFF" : "#F2F2F2",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.action.selected,
                        },
                      }}
                      hover
                    >
                      <TableCell>
                        {row.status === "Completed" ? (
                          <Link
                            component={NavLink}
                            to={getToolPageRoute(
                              row.tool_name,
                              row.run_ID,
                              row.fund_ID,
                              row.Reporting_Date
                            )}
                            underline="hover"
                            color="primary"
                          >
                            {row.run_ID}
                          </Link>
                        ) : (
                          row.run_ID
                        )}
                      </TableCell>

                      <TableCell>{row.tool_name}</TableCell>
                      <TableCell>{row.user}</TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {row.file_name}
                      </TableCell>
                      <TableCell>{row.start_time}</TableCell>
                      <TableCell>{row.run_time}</TableCell>
                      <TableCell>
                        {row.status === "Completed" ? (
                          <Chip
                            label="Completed"
                            color="success"
                            icon={<CheckCircle />}
                          />
                        ) : (
                          <Chip
                            label="In Progress"
                            color="warning"
                            icon={<HourglassEmpty />}
                          />
                        )}
                      </TableCell>
                      <TableCell>{row.run_progress}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={2}>
              <Pagination
                count={Math.ceil(filteredData.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default RunStatics;
