import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import React, { FC, useEffect, useRef, useState } from "react";
import { convertToDateFormat } from "../../../../utils/dateUtil";
import axiosInstance from "../../../../utils/axios/axios.instance";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "../../../../components/Workflow/ModalForAIExtraction";
import { toast } from "react-toastify";

const AddCapitalCallRequestForm: FC<{
  isTool?: boolean;
  handleNext?: () => void;
}> = ({ isTool, handleNext }) => {
  const navigate = useNavigate();

  const { user } = useAuth0();
  const [date, setDate] = useState<Date | null>(new Date());
  const [fundKeys, setFundKeys] = useState<{ [key: string]: any }>({});
  const [fundName, setFundName] = useState("");
  const [fund_ID, setFund_ID] = useState("");
  const [fundNames, setFundNames] = useState([]);
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [data, setData] = useState("");
  const [fileName, setFileName] = useState("");

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const uploadAnother = () => {
    resetData();
    handleCloseModal();
  };
  const moveToWorkflow = () => {
    navigate("/workFlow");
    handleCloseModal();
  };
  const moveToNextPage = () => {
    if (isTool && handleNext) {
      handleNext();
    } else {
      navigate("/AI_CapitalCall_Update");
    }
    handleCloseModal();
  };
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name.slice(0, -4));
    }
  };
  const resetData = () => {
    setData("");
    setIsButtonDisabled(false);
    setFile(null);
  };
  const runscript = async () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 10000);
    if (file) {
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      try {
        toast.info("Processing the file. Please wait...");
        const formData = new FormData();
        const name = file.name.slice(0, -4);
        formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file
        formData.append("timestamp", time);
        formData.append("fund_ID", fund_ID);
        formData.append("file_name", name);
        formData.append("email", userEmail);
        formData.append("date", convertToDateFormat(date as Date));
        handleOpenModal();

        const response = await axiosInstance.post(
          "/CapitalCallExtraction",
          formData,
          {
            params: {
              timestamp: time,
              name: name,
            },
          }
        );
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      toast.error("No file selected.");
      console.error("No file selected.");
    }
  };
  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names");
    const responseData = response.data.data;
    responseData.sort((a: { Fund_Name: string }, b: { Fund_Name: string }) => {
      return a.Fund_Name.toLowerCase().localeCompare(b.Fund_Name.toLowerCase());
    });
    setFundNames(response.data.data);
    setFund_ID(response.data.data[0]["fund_ID"]);
    let keys: { [key: string]: any } = {};
    // eslint-disable-next-line array-callback-return
    response.data.data.map((fund: { [x: string]: any }) => {
      keys[fund["fund_ID"]] = fund["Fund_Name"];
    });
    setFundKeys(keys);
    setFundName(response.data.data[0]["Fund_Name"]);
  };
  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    getFundNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFundChange = (event: any) => {
    setFund_ID(event.target.value);
    setFundName(fundKeys[event.target.value]);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "60%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fund_ID}
                  style={{ maxWidth: "230px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundNames.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingLeft: "3rem" }}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select Capital Call due date
              </Typography>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "1px",
                }}
              >
                <DatePicker
                  label=" Capital Call due date"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                border: "2px dashed #007BFF",
                padding: "30px",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
              }}
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              onClick={() => inputFileRef.current?.click()}
            >
              <Typography variant="body1" style={{ color: "#007BFF" }}>
                {file ? file.name : "Drag & Drop or Click to Select a File"}
              </Typography>
              <input
                accept="application/pdf"
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                onClick={(e: any) => (e.target.value = "")}
                ref={inputFileRef}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <TextField
                fullWidth
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                type="email"
                label="Email Address"
                variant="outlined"
                placeholder="email@example.com"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                disabled={isButtonDisabled}
                onClick={runscript}
                style={{
                  backgroundColor: isButtonDisabled ? "#9E9E9E" : "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "18px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: isButtonDisabled ? "default" : "pointer",
                  outline: "none",
                  marginRight: "10px", // Add some space between the buttons
                }}
              >
                Process
              </button>
              <button
                onClick={resetData}
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "18px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                Reset
              </button>
            </div>

            <div
              style={{
                alignContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "10%",
              }}
            >
              <div style={{ paddingTop: "10px", marginRight: "4%" }}>
                {data}
              </div>
              {data ===
                "Unfortunately we cannot process encrypted files at this time. Please come back in a few weeks for the next release" && (
                <button
                  onClick={resetData}
                  style={{
                    marginLeft: "40%",
                    marginTop: "20px",
                    backgroundColor: "#D32F2F",
                    color: "white",
                    border: "none",
                    padding: "12px 24px",
                    borderRadius: "18px",
                    fontSize: "16px",
                    fontWeight: "500",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  Reset
                </button>
              )}
            </div>
          </div>
        </div>
        <Modal
          email={userEmail}
          handleClose={uploadAnother}
          fundName={fundName}
          fileName={fileName}
          show={showModal}
          handleUploadAnother={uploadAnother}
          handleMoveToWorkflow={moveToWorkflow}
          handleNextPage={moveToNextPage}
          isPortfolio={false}
          isTool={isTool}
        />
      </div>
    </>
  );
};

export default AddCapitalCallRequestForm;
