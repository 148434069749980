/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ExcelSheetSVG, MultipleFileSVG } from "../Svgs";
import PortfolioFileUploads from "./portfolio_AI_page/portfolioFileUpload";
import PortfolioDataUploads from "./portfolio_AI_page/portfolioDataUpdate";
import { useSearchParams } from "react-router-dom";
import InfoCard from "./Info";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const PortfolioReviewAIPage = () => {
  const [currentTool, setCurrentTool] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const updateData = searchParams.get("UpdateData");
  const tools = [
    {
      id: 1,
      name: "Upload File",
      icon: <ExcelSheetSVG />,
    },
    {
      id: 2,
      name: "Update Data",
      icon: <MultipleFileSVG />,
    },
  ];
  useEffect(() => {
    if (updateData) {
      setCurrentTool(2);
    }
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Quarterly Updates" />

      <Typography
        variant="h3"
        style={{
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
        }}
      >
        Quarterly Updates
      </Typography>
      <Divider my={6} />
      <InfoCard
        purpose="This tool provides the text summarization information to be used in reporting (Page 1 and Page 2) for the Front-Office."
        input="A quarterly reporting PDF document that contains the necessary data for extraction."
        output="You will receive an email with the summarized information once the extraction process is completed."
      />

      {/* <Typography
        style={{
          textAlign: "justify",
          marginBottom: "20px",
          width: "80%",
        }}
      >
        This tool provides the text summarization information to be used in
        reporting ( Page 1 and Page 2) for the Front-Office.
      </Typography> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  marginBottom: "60px",
                  cursor: "pointer",
                }}
              >
                {tools.map((tool) => {
                  return (
                    <div
                      key={tool.id}
                      onClick={() => {
                        setCurrentTool(tool.id);
                      }}
                      style={{
                        minWidth: "200px",
                        height: "60px",
                        fontSize: "1.1rem",
                        border: "3px solid",
                        marginRight: "20px",
                        marginTop: "20px",
                        backgroundColor: "#1B2430",
                        color: "#fff",
                        fontWeight: "bold",
                        borderColor:
                          tool.id === currentTool ? "#C5585C" : "transparent",
                        opacity: tool.id === currentTool ? "1" : "0.5",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          padding: "6px 10px",
                        }}
                      >
                        <div style={{ marginTop: "4px" }}>{tool.name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {currentTool === 1 && <PortfolioFileUploads />}
              {currentTool === 2 && <PortfolioDataUploads />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default PortfolioReviewAIPage;
