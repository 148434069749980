import {
  Divider as MuiDivider,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  CardContent,
  Card,
  Box,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import InfoCard from "./Info";
import { CloudUpload } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import axiosInstance from "../../utils/axios/axios.instance";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import MultipleFileUpload from "../MultiFileUpload/multifileUPload";

const Divider = styled(MuiDivider)(spacing);

const TableComparissionTool = () => {
  const { user } = useAuth0();
  const [showReset, setShowReset] = useState(false);
  const fundData = [
    {
      Fund_Name: "Georgian Alignment Fund II",
      Fund_Type: "Easy",
      fund_FileCount: 2,
      scriptNameDir: "GAFII",
    },
    {
      Fund_Name: "Versant Voyageurs I",
      Fund_Type: "Moyen",
      fund_FileCount: 2,
      scriptNameDir: "VVI",
    },
    // {
    //   Fund_Name: "Fonds CTI Sciences de la Vie III",
    //   Fund_Type: "Moyen",
    //   fund_FileCount: 2,
    //   scriptNameDir: "FCSIII",
    // },
    // {
    //   Fund_Name: "Fonds Cycle Capital IV",
    //   Fund_Type: "Moyen",
    //   fund_FileCount: 2,
    //   scriptNameDir: "FCCIV",
    // },
    // {
    //   Fund_Name: "Georgian Growth Fund VI",
    //   Fund_Type: "Moyen",
    //   fund_FileCount: 2,
    //   scriptNameDir: "GGFVI",
    // },
    // {
    //   Fund_Name: "Iris Capital Fund III",
    //   Fund_Type: "Moyen",
    //   fund_FileCount: 2,
    //   scriptNameDir: "ICFIII",
    // },
    // {
    //   Fund_Name: "ForgePoint Capital II",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "FPCII",
    // },
    // {
    //   Fund_Name: "Georgian Partners Fund II",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "GPFII",
    // },
    // {
    //   Fund_Name: "Georgian Partners Fund III",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "GPFIII",
    // },
    // {
    //   Fund_Name: "Georgian Partners Fund IV",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "GPFIV",
    // },
    // {
    //   Fund_Name: "Georgian Partners Fund V",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "GPFV",
    // },
    // {
    //   Fund_Name: "Lumira Capital II",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "LCII",
    // },
    // {
    //   Fund_Name: "Lumira Ventures III",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "LVIII",
    // },
    // {
    //   Fund_Name: "Lumira Ventures IV",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "LVIV",
    // },
    // {
    //   Fund_Name: "Merck Lumira Biosciences Fund (Québec)",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "MLBFQ",
    // },
    // {
    //   Fund_Name: "Real Investment Fund 17",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "RIF17",
    // },
    // {
    //   Fund_Name: "Rho Canada Ventures II",
    //   Fund_Type: "Complex",
    //   fund_FileCount: 2,
    //   scriptNameDir: "RCVII",
    // },
    {
      Fund_Name: "Versant V",
      Fund_Type: "Complex",
      fund_FileCount: 4,
      scriptNameDir: "VV",
    },
  ];
  const [userEmail, setUserEmail] = useState("");
  const [selectedFund, setSelectedFund] = useState(fundData[0].Fund_Name);
  const [fileCount, setFileCount] = useState(fundData[0].fund_FileCount);
  const [scriptNameDir, setScriptNameDir] = useState(fundData[0].scriptNameDir);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log("showReset", showReset);
    let resetTimer: NodeJS.Timeout;
    if (showReset) {
      resetTimer = setTimeout(() => {
        handleReset();
      }, 10000); // 10 seconds
    }
    return () => clearTimeout(resetTimer); // Cleanup on unmount or manual reset
  }, [showReset]);

  useEffect(() => {
    const selected = fundData.find((fund) => fund.Fund_Name === selectedFund);
    if (selected) {
      setFileCount(selected.fund_FileCount);
      setScriptNameDir(selected.scriptNameDir);
      // setFiles(new Array(selected.fund_FileCount).fill(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFund]);
  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }

    // eslint-disable-next-line
  }, []);

  const handleFundChange = (event: any) => {
    const selectedFundName = event.target.value;
    setSelectedFund(selectedFundName);
    const selected = fundData.find(
      (fund) => fund.Fund_Name === selectedFundName
    );
    if (selected) {
      setFileCount(selected.fund_FileCount);
      setScriptNameDir(selected.scriptNameDir);
      // setFiles(new Array(selected.fund_FileCount).fill(null));
      // setFileNames(new Array(selected.fund_FileCount).fill(""));
    }
  };
  const uploadFiles = async () => {
    const uploadedFiles = files.filter((file) => file !== null);
    if (uploadedFiles.length < fileCount) {
      toast.error(
        `Please upload all required files. You need to upload at least ${fileCount} files.`
      );
      return;
    }

    toast.info("Please wait while we process your request...");
    const formData = new FormData();
    let time = new Date().toISOString();
    time = time.replace(/:/g, "-");

    files.forEach((file) => {
      if (file) {
        formData.append("pdf", file);
      }
    });

    try {
      setShowReset(true);
      setIsLoading(true);

      const response = await axiosInstance.post(
        `multi-vehical-fund-extraction`,
        formData,
        {
          params: {
            timestamp: time,
            email: userEmail,
            folderName: scriptNameDir,
          },
        }
      );
      setIsLoading(false);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  // const handleFileChange = (index: number, file: File | null) => {
  //   const newFiles = [...files];
  //   const newFileNames = [...fileNames];
  //   if (file) {
  //     newFiles[index] = file;
  //     newFileNames[index] = file.name;
  //   } else {
  //     newFiles[index] = null;
  //     newFileNames[index] = "";
  //   }
  //   setFiles(newFiles);
  //   setFileNames(newFileNames);
  // };

  // const handleDrop = (event: React.DragEvent, index: number) => {
  //   event.preventDefault();
  //   const file = event.dataTransfer.files?.[0] || null;
  //   handleFileChange(index, file);
  // };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };
  const handleReset = () => {
    // setFiles(new Array(fundData[0].fund_FileCount).fill(null));
    // setFileNames(new Array(fundData[0].fund_FileCount).fill(""));
    setFiles([]);

    setShowReset(false);
    setIsLoading(false);
  };
  const handleFilesSelected = (newFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  return (
    <>
      <Typography
        variant="h3"
        style={{
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
        }}
      >
        Table Comparison Tool
      </Typography>
      <Divider my={6} />
      <InfoCard
        purpose="This tool analyzes the provided pdfs and emails an xls containing the table comparisons in the desired xls format as needed for Versant."
        input="A PDF document that includes tables for extraction."
        output="An email with the extracted data in an XLS file will be sent to you upon completion."
      />

      <Card style={{ marginBottom: "30px", padding: "20px" }}>
        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Select a Fund</InputLabel>
                <Select
                  value={selectedFund}
                  onChange={handleFundChange}
                  label="Select a Fund"
                >
                  {fundData.map((fund, index) => (
                    <MenuItem key={index} value={fund.Fund_Name}>
                      {fund.Fund_Name} ({fund.Fund_Type})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Divider style={{ marginBottom: "30px" }} />

      <Card style={{ marginBottom: "30px", padding: "20px" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Upload Required Files
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {`Please upload ${fileCount} PDF(s) for the selected fund: ${selectedFund}`}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                maxWidth: "600px",
                width: "100%",
              }}
            >
              <MultipleFileUpload
                acceptedFileTypes="application/pdf"
                multiple={true}
                files={files}
                onFilesSelected={handleFilesSelected}
                onFileDelete={handleFileDelete}
              />
            </div>
          </div>

          <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
            <TextField
              fullWidth
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
              type="email"
              label="Email Address"
              variant="outlined"
              placeholder="email@example.com"
              sx={{
                maxWidth: "600px",
                width: "100%",
                textAlign: "center",
              }}
            />
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "20px", display: "flex" }}
          >
            <LoadingButton
              style={{
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                padding: "8px 20px",
                borderRadius: "20px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                outline: "none",
              }}
              color="secondary"
              onClick={uploadFiles}
              loading={isLoading}
              endIcon={<SendIcon />}
              loadingPosition="end"
              variant="contained"
            >
              <span>Submit Files</span>
            </LoadingButton>

            {showReset && (
              <LoadingButton
                style={{
                  backgroundColor: "red",
                  marginLeft: "20px",
                  color: "white",
                  border: "none",
                  padding: "8px 35px",
                  borderRadius: "20px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  outline: "none",
                }}
                onClick={handleReset}
                variant="contained"
              >
                <span>Reset</span>
              </LoadingButton>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default TableComparissionTool;
