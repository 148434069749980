import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MailIcon from "@mui/icons-material/Mail";

const InfoCard = ({
  purpose,
  input,
  output,
}: {
  purpose: string;
  input: string;
  output: string;
}) => {
  return (
    <Box
      style={{
        backgroundColor: "#f5f5f5",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
      }}
    >
      <Typography
        variant="body1"
        style={{
          textAlign: "justify",
          margin: "10px 0",
          lineHeight: "1.8",
          color: "#555",
        }}
      >
        <Box display="flex" alignItems="center" mb={3}>
          <InfoIcon style={{ color: "#007BFF", marginRight: "10px" }} />
          <Typography variant="subtitle2">
            <strong>Purpose:</strong> {purpose}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={3}>
          <FileUploadIcon style={{ color: "#007BFF", marginRight: "10px" }} />
          <Typography variant="subtitle2">
            <strong>Input:</strong> {input}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <MailIcon style={{ color: "#007BFF", marginRight: "10px" }} />
          <Typography variant="subtitle2">
            <strong>Output:</strong> {output}
          </Typography>
        </Box>
      </Typography>
    </Box>
  );
};

export default InfoCard;
