/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { convertStringToDate } from "../../../../utils/dateUtil";
import axiosInstance from "../../../../utils/axios/axios.instance";
import { toast } from "react-toastify";
import styled from "@emotion/styled";
import {
  Button,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Card = styled(MuiCard)(spacing);

const form = React.createRef<HTMLFormElement>();

const CapitalCallUpdateForm = () => {
  const [fetchTriggered, setFetchTriggered] = useState(false);

  const [date, setDate] = useState<Date | null>(new Date());
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selectedFundId, setSelectedFundId] = useState<string>("");
  const [selectedFundName, setSelectedFundName] = useState<string>("");
  const [inputvalue, setInputValue] = useState<any>(null);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const fundID = searchParams.get("fundID");
  const reportingDate = searchParams.get("reportingDate");
  const selectedRunID = searchParams.get("runID");
  const styles: { [key: string]: CSSProperties } = {
    tableLabel: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left",
      fontSize: "15px",
    },
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "left", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "70%", // Make input take full width of its cell
    },
    select: {
      display: "flex", // Make select fill the cell
      backgroundColor: "#f2f2f2", // Background color
      color: "#333", // Text color
      padding: "10px", // Padding
      border: "1px solid #ccc", // Border
      borderRadius: "5px", // Border radius
      outline: "none", // Remove outline when selected
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
      width: "200px", // Width
      cursor: "pointer", // Cursor style
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "18px",
      cursor: "pointer",
    },
  };
  const [extractedData, setExtractedData] = useState<any[]>([]);
  const [filename, setFileName] = useState<FundName[]>([]);
  const [runIDs, setRunIDs] = useState<any[]>([]);
  const [currentRunID, setCurrentRunID] = useState("");
  const [fundPDF, setFundPDF] = useState<string>("");
  const [LPNames, setLPNames] = useState<any[]>([]);
  const getLPNames = async () => {
    try {
      const response = await axiosInstance.get("/LP_names");
      const responseData = await response.data.data;
      const LP_list: any = [];
      responseData.forEach((data: any) => {
        LP_list.push(data.LP_name);
      });
      setLPNames(LP_list);
    } catch (error) {
      console.error(error);
    }
  };
  interface FundName {
    fund_ID: string;
    Fund_Name: string;
    cc_Date: string;
  }

  interface FormDataObject {
    [fieldName: string]: string;
  }
  useEffect(() => {
    document.title = "AI Extracted Capital Call Data";
    setDate(reportingDate ? convertStringToDate(reportingDate) : new Date());
    getFileNames();
    getLPNames();
  }, []);

  useEffect(() => {
    if (fundID && reportingDate && filename.length > 0 && !fetchTriggered) {
      const selectedFund = filename.find(
        (fund: any) => fund.fund_ID === fundID
      );

      if (selectedFund && selectedRunID) {
        setCurrentRunID(selectedRunID || "");
        setSelectedFundName(selectedFund.Fund_Name);
        setSelectedFundId(fundID);
        setDate(convertStringToDate(reportingDate));

        // Call the data-fetching functions
        getData(fundID, selectedRunID);
        getRefData(fundID);
        getcurrentData(selectedFund.Fund_Name);

        // Set fetchTriggered to true to prevent further calls
        setFetchTriggered(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundID, reportingDate, filename, fetchTriggered]);

  const showPdf = async (name: string) => {
    const encodedName = encodeURIComponent(name);
    await axiosInstance.get("/CapitalCalPdf/" + encodedName).then((res) => {
      setFundPDF(res.data.data);
    });
  };
  const handleButtonClick = () => {
    const capitalcallDate = inputvalue["cc_Date"];
    const dateRegex = /^([0-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;
    if (!dateRegex.test(capitalcallDate)) {
      toast.error("Please enter a valid date in the format dd-mm-yyyy");
      return;
    }
    updateFileData();
  };
  const convertToISO = (dateStr: string): string => {
    const [day, month, year] = dateStr.split("-");
    const date = new Date(
      Date.UTC(Number(year), Number(month) - 1, Number(day))
    );
    return date.toISOString();
  };

  const updateFileData = async () => {
    try {
      const idSelector = document.getElementById(
        "runIDSelector"
      ) as HTMLSelectElement;
      if (idSelector && idSelector.options.length > 0) {
        idSelector.selectedIndex = 0; // Set the selected index to the first option
      }
      let formData2: FormDataObject = {};
      formData2 = inputvalue;
      formData2["cc_Date"] = convertToISO(inputvalue["cc_Date"]);
      // formData2["cc_ID"] = selectedCapitalCall + selectedFundName;
      const form = new FormData();
      delete formData2._id;
      form.append("updateKey", isUpdate ? "true" : "false");
      Object.keys(formData2).forEach((key) => {
        let value = formData2[key];
        if (value === null) {
          // Assume string fields should default to "", numeric fields to 0
          // You might need more complex logic here if you have specific fields that need different handling
          value = typeof formData2[key] === "number" ? "0" : "";
        }
        // Append the key and either the original or the replaced value to the form
        form.append(key, value as string);
      });
      const response = await axiosInstance.post("/add_capitalCallData", form, {
        headers: {
          "Content-Type": "multipart/form-data", // or you can omit this line
        },
      });
      if (response.data.data === "error") {
        toast.error("Error while updating Data.");
      } else {
        setIsSelected(false);
        toast.success("Data updated successfully");
      }
    } catch (err) {
      toast.error("Error while updating Data.");
    } finally {
      setTimeout(() => {
        getData(selectedFundId);
        getRefData(selectedFundId);
        getcurrentData(selectedFundName);
      }, 3000);
    }
  };
  const keys = [
    "Fund_Name",
    "LP_name",
    "cc_Date",
    "cc_No",
    "cc_ID",
    "cc_noticeDate",
    "cc_totalCapitalCalled",
    "cc_LPname_totalcapitalcalled",
    "cc_managementFees",
    "cc_other",
    "cc_VAT",
    "cc_orgFees",
    "cc_partnershipExp",
  ];

  const getData = async (id: string, runID?: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        `/extractedCapitalCalData/${id}/${dat}${
          runID ? `?run_id=${runID}` : ""
        }`
      );
      let newData = response.data.data[0];
      if (response.data.data.length === 0) {
        toast.error("No Extraction data found for this fund");
        return;
      }
      const UpdatedData: any = Object.keys(newData)
        .filter((key) => keys.includes(key))
        .reduce((obj: any, key) => {
          obj[key] = newData[key];
          return obj;
        }, {});
      const keysNew = UpdatedData ? Object.keys(UpdatedData) : [];
      let dataKey: any = [];
      keysNew.forEach((key) => {
        if (key === "_id") return;
        if (
          newData[key] !== null &&
          newData[key] !== "" &&
          newData[key] !== 0
        ) {
          dataKey.push(key);
        }
      });
      const filteredArr1 = dataKey.filter((item: string) =>
        keyOrder.includes(item)
      );
      delete filteredArr1["LP_name"];
      const keyOrderTemp = keyOrder.filter(
        (key) => key !== "LP_name" && key !== "Fund_Name" && key !== "cc_Date"
      );
      const rearrangedKeyOrder = [
        "LP_name",
        "Fund_Name",
        "cc_Date",
        ...filteredArr1,
        ...keyOrderTemp.filter((key) => !filteredArr1.includes(key)), // Remaining fields
      ];

      setKeyOrder(rearrangedKeyOrder);

      setExtractedData(UpdatedData);
      if (newData?.pdf_link !== undefined) {
        showPdf(newData.pdf_link);
      } else {
        setFundPDF("");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getRefData = async (fundName: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/getCapitalCallDataByID/" + fundName + "/" + dat
      );
      if (response.data.data.length === 0) {
        toast.error("No data found for this company");
      }
      const responseData = await response.data.data[0];
      const UpdatedData: any = Object.keys(staticData)
        .filter((key) => keys.includes(key))
        .reduce((obj: any, key) => {
          obj[key] = responseData[key];
          return obj;
        }, {});
      UpdatedData != null ? setIsSelected(true) : setIsSelected(false);
      setStaticData(UpdatedData);
    } catch (error) {
      console.error(error);
    }
  };
  const getcurrentData = async (fundName: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/getCapitalCalCurrentData/" + fundName + "/" + dat
      );
      const responseData = await response.data.data;

      if (responseData.length === 0) {
        setInputValue({
          _id: "",
          Fund_Name: fundName,
          cc_Date: dat,
          cc_totalCapitalCalled: 0,
          cc_LPname_totalcapitalcalled: 0,
          cc_managementFees: 0,
          cc_ID: dat + fundName,
          fund_ID: selectedFundId,
          cc_orgFees: 0,
          cc_partnershipExp: 0,
          cc_other: 0,
          cc_No: "",
          cc_VAT: 0,
        });
        setIsUpdate(false);
      } else {
        // Convert cc_Date to dd-mm-yyyy format
        const originalDate = responseData[0].cc_Date;
        const formattedDate = new Date(originalDate)
          .toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-");

        // Update the cc_Date field in responseData[0] with the formatted date
        responseData[0].cc_Date = formattedDate;

        // Store the updated object in inputValue
        setInputValue(responseData[0]);
        setIsUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFileNames = async () => {
    try {
      const response = await axiosInstance.get("/extractedCapitalCalName");
      const responseData = await response.data.data;
      responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      setFileName(responseData);
      if (fundID) {
        setSelectedFundId(fundID);
        const selectedFund = responseData.find(
          (fund: any) => fund.fund_ID === fundID
        );
        setSelectedFundName(selectedFund.Fund_Name);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const copyToClipboardAndPaste = (key: any, value: any) => {
    if (typeof value === "string" && value.includes(",")) {
      const cleanedValue = value.replace(/,/g, "");
      const parsedValue = parseFloat(cleanedValue);
      if (!isNaN(parsedValue)) {
        value = parsedValue;
      }
    }

    setInputValue((prevInputValue: any) => ({
      ...prevInputValue,
      [key]: value,
    }));
  };

  const [keyOrder, setKeyOrder] = useState([
    "Fund_Name",
    "LP_name",
    "cc_Date",
    "cc_limitedPartner",
    "cc_No",
    "cc_ID",
    "cc_noticeDate",
    "cc_managementFees",
    "cc_other",
    "cc_VAT",
    "cc_orgFees",
    "cc_partnershipExp",
    "fund_currency",
    "created_At",
    "cc_totalCapitalCalled",
  ]);
  const constKey = ["Fund_Name", "cc_limitedPartner", "cc_ID"];
  const defaultKey = ["Fund_Name", "cc_limitedPartner", "cc_ID", "cc_Date"];

  const validationKeys = [
    "cc_managementFees",
    "cc_other",
    "cc_VAT",
    "cc_orgFees",
    "cc_partnershipExp",
    "cc_totalCapitalCalled",
  ];
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/workflow"); // Navigate to the /workflow page
  };

  const [staticData, setStaticData]: any = useState({
    cc_No: "",
    cc_Date: "",
    cc_noticeDate: "",
    cc_managementFees: "",
    cc_orgFees: "",
    cc_partnershipExp: "",
    cc_other: 0,
    cc_VAT: 0,
    created_At: "",
    record_type: "",
    run_id: "",
    model_name: "",
    Fund_Name: "",
    fund_ID: "",
    cc_totalCapitalCalled: 0,
    LP_name: "",
  });

  useEffect(() => {
    if (selectedFundId) {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      axiosInstance
        .get("/getCapitalCallRunIDs/" + selectedFundId + "/" + dat)
        .then((response) => {
          setRunIDs(response.data.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFundId, date]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div style={{ display: "flex" }}>
                <label
                  style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Select Fund :
                </label>
                <select
                  style={{
                    backgroundColor: "#f2f2f2",
                    marginRight: "20px",
                    color: "#333",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    width: "250px",
                    cursor: "pointer",
                  }}
                  value={selectedFundId}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setSelectedFundName("");
                    setSelectedFundId("");
                    setCurrentRunID("");
                    setRunIDs([]);
                    setInputValue(null);
                    const selectedIndex = e.target.selectedIndex - 1;
                    if (selectedIndex >= 0) {
                      const selectedFund = filename.find(
                        (fund) => fund.fund_ID === e.target.value
                      );
                      setSelectedFundName(selectedFund?.Fund_Name || "");
                      setSelectedFundId(e.target.value);
                      const date = convertStringToDate(
                        filename[selectedIndex].cc_Date
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-")
                      );

                      setDate(date);
                    } else {
                      setSelectedFundId("");
                    }
                  }}
                >
                  <option value="">please Select Fund</option>
                  {filename.map((fileName, index) => {
                    return (
                      <option
                        key={filename[index].Fund_Name}
                        value={filename[index].fund_ID}
                        data-fund-name={filename[index].fund_ID}
                      >
                        {filename[index].Fund_Name}
                      </option>
                    );
                  })}
                </select>
                <DatePicker
                  label="Capital call due date"
                  format="dd/MM/yyyy"
                  value={date}
                  onChange={(newValue) => {
                    setCurrentRunID("");
                    setInputValue(null);
                    setDate(newValue);
                  }}
                />

                <button
                  onClick={() => {
                    if (date && selectedFundId) {
                      setIsSelected(false);
                      getData(selectedFundId);
                      getRefData(selectedFundId);
                      getcurrentData(selectedFundName);
                      setCurrentRunID("");
                    } else {
                      alert("Please select Fund and date");
                    }
                  }}
                  type="submit"
                  style={{
                    marginLeft: "20px",
                    padding: "12px 24px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "18px",
                    cursor: "pointer",
                  }}
                >
                  Get data
                </button>
                <Button
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  style={{
                    marginLeft: "auto", // This will push the button to the end
                    padding: "12px 24px",
                    backgroundColor: "#007bff",
                    borderRadius: "18px",
                  }}
                >
                  Back
                </Button>
              </div>

              <div style={{ marginTop: "30px" }}>
                {selectedFundName && inputvalue && (
                  <>
                    <label
                      style={{
                        marginRight: "10px",
                        marginTop: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Select Run ID :
                    </label>

                    <select
                      id="runIDSelector"
                      style={{
                        backgroundColor: "#f2f2f2",
                        marginLeft: "30px",
                        color: "#333",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        outline: "none",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                        width: "280px",
                        height: "52px",
                        cursor: "pointer",
                      }}
                      value={currentRunID} // Bind the value to currentRunID
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const selectedIndex = e.target.selectedIndex - 1;
                        if (selectedIndex >= 0) {
                          const selectedID = runIDs.find((id) => {
                            return id.run_id === e.target.value;
                          });
                          setCurrentRunID(selectedID?.run_id || "");
                          getData(selectedFundId, selectedID?.run_id); // Pass selected runID to getData
                        } else {
                          setCurrentRunID("");
                          getData(selectedFundId);
                        }
                      }}
                    >
                      <option value="">Please select Run ID</option>
                      {runIDs.map((runID, index) => (
                        <option key={index} value={runID.run_id}>
                          {runID.run_id}
                        </option>
                      ))}
                    </select>
                  </>
                )}
                {currentRunID !== "" && fundPDF !== "" && (
                  <>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default anchor behavior
                        window.open(
                          fundPDF,
                          "_blank",
                          "noopener,noreferrer,width=800,height=600"
                        );
                      }}
                      style={{
                        marginLeft: "20px",
                        color: "#0066cc",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      View Source PDF
                    </a>
                  </>
                )}
              </div>

              {isSelected && inputvalue && (
                <div>
                  <div style={styles.buttonContainer}>
                    <button
                      onClick={handleButtonClick}
                      type="button"
                      style={styles.button}
                    >
                      {isUpdate ? "Update" : "Add"}
                    </button>
                  </div>
                  <form
                    ref={form}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div>
                      <table
                        style={{
                          textAlign: "left",
                          width: "100%",
                          borderCollapse: "separate", // Allows the borders to be separated for spacing
                          borderSpacing: "1px", // Sets the space between the cells
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                ...styles.tableLabel,
                                width: "15%", // Set width to 25%
                              }}
                            >
                              Key name
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "35%", // Set width to 25%
                              }}
                            >
                              Update value manually
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "25%", // Set width to 25%
                              }}
                            >
                              AI extracted value (Page No.)
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                color: "grey",
                                textAlign: "center",
                                width: "25%", // Set width to 25%
                              }}
                            >
                              Previous Entry
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(staticData)
                            .filter(
                              (key) =>
                                key !== "_id" &&
                                key !== "fund_ID" &&
                                key !== "cc_ID" &&
                                key !== "cc_investment" &&
                                key !== "cc_investor" &&
                                key !== "fund_currency" &&
                                !key.includes("pageNumber")
                            )
                            .sort(
                              (a, b) =>
                                keyOrder.indexOf(a) - keyOrder.indexOf(b)
                            )
                            .map((key, index) => {
                              const file = extractedData as {
                                [key: string]: any;
                              };
                              const refrence = staticData as {
                                [key: string]: any;
                              };
                              if (key === "_id") return null;
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    paddingTop: "30px",
                                    borderBottom: "1px solid #cccccc",
                                    backgroundColor:
                                      index % 2 === 0 ? "#f2f2f2" : "#FFFFFF",
                                  }}
                                >
                                  <td
                                    style={{
                                      ...styles.label,
                                      paddingBottom: "10px",
                                      width: "15%",
                                    }}
                                  >
                                    {key.replace(/_/g, " ")}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                    }}
                                  >
                                    {key !== "LP_name" ? (
                                      <TextField
                                        multiline
                                        minRows={1}
                                        maxRows={5}
                                        id={index.toString()}
                                        name={key}
                                        style={{
                                          width: "100%", // Make input take full width of its cell
                                          textAlign: "justify",
                                          background: "#fff", // Adds a white background
                                          borderColor: "#007bff", // Blue border color
                                          borderWidth: "2px", // Border width
                                          borderRadius: "4px", // Rounded corners
                                        }}
                                        value={
                                          inputvalue != null
                                            ? inputvalue[key]
                                            : ""
                                        }
                                        type="text"
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (validationKeys.includes(key)) {
                                            // Check if value is a number or empty
                                            if (
                                              value === "" ||
                                              /^[0-9]*$/.test(value)
                                            ) {
                                              setInputValue(
                                                (prevInputValue: any) => ({
                                                  ...prevInputValue,
                                                  [key]: value,
                                                })
                                              );
                                            }
                                          } else {
                                            setInputValue(
                                              (prevInputValue: any) => ({
                                                ...prevInputValue,
                                                [key]: value,
                                              })
                                            );
                                          }
                                        }}
                                        disabled={constKey.includes(key)}
                                      />
                                    ) : (
                                      <select
                                        id={index.toString()}
                                        name={key}
                                        style={{
                                          width: "100%", // Full width
                                          textAlign: "justify",
                                          background: "#f0f4f7", // Light background color
                                          borderColor: "black", // Blue border color
                                          borderWidth: "2px", // Border width
                                          borderRadius: "4px", // Rounded corners
                                          height: "100%",
                                          padding: "8px", // Padding inside the dropdown
                                          color: "#333", // Text color
                                          fontSize: "14px", // Font size
                                          outline: "none", // Remove default outline
                                          appearance: "none", // Remove default arrow for custom styling
                                          backgroundImage:
                                            "url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2210%22 height=%225%22 viewBox=%220 0 10 5%22><path fill=%22%23007bff%22 d=%22M0 0l5 5 5-5z%22/></svg>')", // Custom dropdown arrow
                                          backgroundRepeat: "no-repeat", // No repeat of the background image
                                          backgroundPositionX:
                                            "calc(100% - 10px)", // Position the arrow on the right
                                          backgroundPositionY: "center", // Center the arrow vertically
                                        }}
                                        value={
                                          inputvalue != null
                                            ? inputvalue[key]
                                            : ""
                                        }
                                        onChange={(e) => {
                                          setInputValue(
                                            (prevInputValue: any) => ({
                                              ...prevInputValue,
                                              [key]: e.target.value,
                                            })
                                          );
                                        }}
                                        disabled={constKey.includes(key)}
                                      >
                                        <option value="" disabled>
                                          Select an LP
                                        </option>{" "}
                                        {/* Placeholder option */}
                                        {LPNames.map((lp, idx) => (
                                          <option key={idx} value={lp}>
                                            {lp}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      ...styles.label,
                                      textAlign: "center",
                                      paddingBottom: "10px",
                                      width: "25%", // Set width to 25%
                                    }}
                                  >
                                    {file &&
                                      currentRunID &&
                                      file[key] &&
                                      file[key] !== "-" &&
                                      file[key] !== "" && (
                                        <>
                                          {file[key]}
                                          {!constKey.includes(key) && (
                                            <IconButton
                                              aria-label="content copy"
                                              size="small"
                                              onClick={() =>
                                                copyToClipboardAndPaste(
                                                  key,
                                                  file[key]
                                                )
                                              }
                                            >
                                              <ContentCopyIcon fontSize="inherit" />
                                            </IconButton>
                                          )}
                                        </>
                                      )}
                                  </td>

                                  <td
                                    style={{
                                      ...styles.label,
                                      textAlign: "center",
                                      color: "grey",
                                      paddingBottom: "10px",
                                      width: "25%", // Set width to 25%
                                    }}
                                  >
                                    {refrence[key] && (
                                      <>
                                        {refrence[key]}
                                        {refrence[key] !== "" &&
                                          refrence[key] !== "-" &&
                                          !defaultKey.includes(key) && (
                                            <IconButton
                                              aria-label="content copy"
                                              size="small"
                                              onClick={() =>
                                                copyToClipboardAndPaste(
                                                  key,
                                                  refrence[key]
                                                )
                                              }
                                            >
                                              <ContentCopyIcon fontSize="inherit" />
                                            </IconButton>
                                          )}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CapitalCallUpdateForm;
