import { Checkbox, Grid, TextField } from "@mui/material";
import React from "react";
import { FormFieldProps } from "../../interfaces/addDataForm.interface";
import { KeyNames } from "../HelperFunctions/addDataForm";

const FormField: React.FC<FormFieldProps> = ({
  fieldName,
  fieldData,
  formData,
  handleChange,
  handleFileChange,
  handleImageError,
  imageError,
  styles,
}) => {
  return (
    <Grid
      item
      xs={12}
      md={4}
      lg={3}
      key={fieldName}
      style={{ paddingTop: "10px" }}
    >
      <div style={styles.label}>{KeyNames[fieldName] || fieldName}</div>
      {fieldData[fieldName].type === "file" ? (
        <div>
          <input
            key={fieldName}
            type="file"
            id={fieldName}
            name={fieldName}
            onChange={handleFileChange}
          />
          <div>
            {fieldData[fieldName].defaultValue && !imageError ? (
              <img
                src={fieldData[fieldName].defaultValue as string}
                width="100px"
                height="100px"
                onError={handleImageError}
                alt="Dynamic Content"
              />
            ) : (
              <p>No image available</p>
            )}
          </div>
        </div>
      ) : fieldData[fieldName].type === "boolean" ? (
        <>
          <Checkbox
            checked={formData[fieldName] === "on"}
            onChange={handleChange}
            name={fieldName}
            color="primary"
          />
        </>
      ) : (
        <>
          <TextField
            minRows={1}
            maxRows={5}
            style={{
              width: "100%",
              background: "#fff",
              borderColor: "#007bff",
              borderWidth: "1px",
              borderRadius: "4px",
              padding: "5px",
            }}
            multiline={fieldData[fieldName].type === "text"}
            type={fieldData[fieldName].type === "number" ? "number" : "text"}
            id={fieldName}
            name={fieldName}
            value={formData[fieldName] || ""}
            onChange={handleChange}
            disabled={fieldName === "LP_name" || fieldName === "Reporting_Date"}
          />
        </>
      )}
    </Grid>
  );
};

export default FormField;
