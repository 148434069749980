import React from "react";
import "../../Style/fileInput.css";
import { Typography } from "@mui/material";

interface FileInputProps {
  id: string;
  label: string;
  onFileChange: (file: File | null) => void;
  material?: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
  id,
  label,
  onFileChange,
  material,
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      onFileChange(event.target.files[0]);
    } else {
      onFileChange(null);
    }
  };

  return (
    <div className="file-input-container">
      {!material ? (
        <label className="file-input-label" htmlFor={id}>
          <span>{label}</span>
        </label>
      ) : (
        <Typography
          variant="subtitle2"
          fontSize="15px"
          style={{
            padding: "10px",
          }}
        >
          {label}:
        </Typography>
      )}
      <input
        className="file-input"
        id="file_input"
        type="file"
        onChange={handleFileChange}
        accept="application/pdf"
        style={{
          height: material ? "52px" : "inherit",
          paddingTop: material ? "13px" : "7px",
        }}
      />
    </div>
  );
};

export default FileInput;
