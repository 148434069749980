import {
  Divider as MuiDivider,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import FileInput from "./fileInput";
import axiosInstance from "../../utils/axios/axios.instance";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

import InfoCard from "./Info";

const Divider = styled(MuiDivider)(spacing);

const MultiFileProcessing = () => {
  const [files, setFiles] = useState<(File | null)[]>([
    null,
    null,
    null,
    null,
    null,
  ]);
  const [userEmail, setUserEmail] = useState<string>("");
  const { user } = useAuth0();

  const handleFileChange = (index: number, file: File | null) => {
    const newFiles = [...files];
    newFiles[index] = file;
    setFiles(newFiles);
  };

  const runscript = async () => {
    if (files.every((file) => file !== null)) {
      try {
        toast.info("Please wait while we process your request...");
        const formData = new FormData();
        files.forEach((file, index) => {
          if (file) {
            formData.append(`files`, file);
          }
        });
        let time = new Date().toISOString();
        time = time.replace(/:/g, "-");

        await axiosInstance.post("/runMultiFileScript", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          params: {
            timestamp: time,
            email: userEmail,
          },
        });
        toast.success("Files processed successfully");
      } catch (err: any) {
        console.error(err);
        toast.error("Error processing files");
      }
    } else {
      toast.error("Please select all files");
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await runscript();
  };

  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        variant="h3"
        style={{
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
        }}
      >
        Multi vehicle funds processing
      </Typography>
      <Divider my={6} />
      <InfoCard
        purpose="This tool analyzes the provided pdfs and emails an xls containing the table-comparisons in the desired xls format as needed for Versant."
        input=" A PDF document that includes tables for extraction."
        output="An email with the extracted data in an XLS file will be sent to you upon completion."
      />

      <form id="file-input-form" onSubmit={handleSubmit}>
        <div
          style={{
            width: "100%",
            marginTop: "50px",
            marginBottom: "40px",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          <TextField
            fullWidth
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            type="email"
            label="Email Address"
            variant="outlined"
            placeholder="email@example.com"
          />
        </div>
        <Grid container spacing={6}>
          {["File 1", "File 2", "File 3", "File 4", "File 5"].map(
            (label, index) => (
              <Grid item key={index} xs={12} sm={6} md={6}>
                <FileInput
                  id={`file_input_${index}`}
                  label={label}
                  onFileChange={(file) => handleFileChange(index, file)}
                />
              </Grid>
            )
          )}
        </Grid>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            id="file-input-submit"
            type="submit"
            className="submit-button"
          >
            Submit Files
          </button>
        </div>
      </form>
    </>
  );
};

export default MultiFileProcessing;
