import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { convertStringToDate } from "../../../../utils/dateUtil";
import axiosInstance from "../../../../utils/axios/axios.instance";
import { toast } from "react-toastify";
import {
  Button,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePicker } from "@mui/x-date-pickers";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const form = React.createRef<HTMLFormElement>();

const DistributionUpdateForm = () => {
  const [fetchTriggered, setFetchTriggered] = useState(false);

  const [message, setMessage] = useState("");
  const [date, setDate] = useState<Date | null>(new Date());
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selectedFundId, setSelectedFundId] = useState<string>("");
  const [selectedFundName, setSelectedFundName] = useState<string>("");
  const [inputvalue, setInputValue] = useState<any>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const styles: { [key: string]: CSSProperties } = {
    tableLabel: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left",
      fontSize: "15px",
    },
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "left", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "70%", // Make input take full width of its cell
    },
    select: {
      display: "flex", // Make select fill the cell
      backgroundColor: "#f2f2f2", // Background color
      color: "#333", // Text color
      padding: "10px", // Padding
      border: "1px solid #ccc", // Border
      borderRadius: "5px", // Border radius
      outline: "none", // Remove outline when selected
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
      width: "200px", // Width
      cursor: "pointer", // Cursor style
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const [extractedData, setExtractedData] = useState<any[]>([]);
  const [filename, setFileName] = useState<FundName[]>([]);
  const [runIDs, setRunIDs] = useState<any[]>([]);
  const [currentRunID, setCurrentRunID] = useState("");
  const [fundPDF, setFundPDF] = useState<string>("");
  const [searchParams] = useSearchParams();
  const fundID = searchParams.get("fundID");
  const reportingDate = searchParams.get("reportingDate");
  const selectedRunID = searchParams.get("runID");

  interface FundName {
    fund_ID: string;
    Fund_Name: string;
    cc_Date: string;
  }

  interface FormDataObject {
    [fieldName: string]: string;
  }
  useEffect(() => {
    document.title = "AI Extracted Distribution Call Data";
    setDate(reportingDate ? convertStringToDate(reportingDate) : new Date());
    getFileNames();
    getLPNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fundID && reportingDate && filename.length > 0 && !fetchTriggered) {
      const selectedFund = filename.find(
        (fund: any) => fund.fund_ID === fundID
      );

      if (selectedFund && selectedRunID) {
        setCurrentRunID(selectedRunID || "");
        setSelectedFundName(selectedFund.Fund_Name);
        setSelectedFundId(fundID);
        setDate(convertStringToDate(reportingDate));

        // Call the data-fetching functions
        getData(fundID, selectedRunID);
        getRefData(fundID);
        getcurrentData(selectedFund.Fund_Name);

        // Set fetchTriggered to true to prevent further calls
        setFetchTriggered(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundID, reportingDate, filename, fetchTriggered]);
  const [keyMap, setKeyMap] = useState<any>({});
  useEffect(() => {
    if (selectedFundId) {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      setRunIDs([]);
      axiosInstance
        .get("/getDistributionCallRunIDs/" + selectedFundId + "/" + dat)
        .then((response) => {
          setRunIDs(response.data.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedFundId]);

  const updateFileData = async () => {
    const form = new FormData();
    let formData2: FormDataObject = inputvalue;
    try {
      if (isUpdate) {
        form.append("formData", JSON.stringify(formData2));
        form.append("distribution_date", inputvalue.distribution_date);
        form.append("fund_ID", selectedFundId);

        const response = await axiosInstance.patch(
          "/updateAIDistributionByID",
          form,
          {}
        );
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setIsSelected(false);
          toast.success(`Data ${isUpdate ? "Updated" : "Added"} successfully`);
        }
      } else {
        formData2.distribution_date = inputvalue.distribution_date;
        formData2.distribution_ID = "";
        form.append("formData", JSON.stringify(formData2));
        const response = await axiosInstance.post(
          "/addAIDistributionData",
          form
        );
        if (response.data === "error") {
          setMessage("Error while updating Data.");
          toast.error("Error while updating Data.");
        } else {
          setIsSelected(false);
          // setFieldData({});
          toast.success(`Data ${isUpdate ? "Updated" : "Added"} successfully`);
        }
      }
    } catch (err) {
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        if (inputvalue.distribution_date) {
          setDate(convertStringToDate(inputvalue.distribution_date));
          setMessage("");
          getData(selectedFundId, inputvalue.distribution_date);
          getRefData(
            selectedFundId
            // convertStringToDate(inputvalue.distribution_date)
          );
          getcurrentData(
            selectedFundId
            // convertStringToDate(inputvalue.distribution_date)
          );
          setCurrentRunID("");
        }
      }, 3000);
    }
  };
  const showPdf = async (name: string) => {
    const encodedName = encodeURIComponent(name);
    await axiosInstance.get("/distributionPdf/" + encodedName).then((res) => {
      setFundPDF(res.data.data);
    });
  };
  const getData = async (id: string, runID?: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        `/extractedDistributionData/${dat}/${id}${
          runID ? `?run_id=${runID}` : ""
        }`
      );
      let newData = response.data.data[0];
      const keys = newData ? Object.keys(newData) : [];
      let dataKey: any = [];
      keys.forEach((key) => {
        if (key === "_id") return;
        if (
          newData[key] !== null &&
          newData[key] !== "" &&
          newData[key] !== 0
        ) {
          dataKey.push(key);
        }
      });
      const filteredArr1 = dataKey.filter((item: string) =>
        keyOrder.includes(item)
      );
      delete filteredArr1["LP_name"];
      const keyOrderTemp = keyOrder.filter(
        (key) =>
          key !== "LP_name" &&
          key !== "Fund_Name" &&
          key !== "distribution_date"
      );
      const rearrangedKeyOrder = [
        "LP_name",
        "Fund_Name",
        "distribution_date",
        ...filteredArr1,
        ...keyOrderTemp.filter((key) => !filteredArr1.includes(key)), // Remaining fields
      ];
      setKeyOrder(rearrangedKeyOrder);
      setExtractedData(newData);
      if (newData?.pdf_link !== undefined) {
        showPdf(newData.pdf_link);
      } else {
        setFundPDF("");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const keys = [
    "Fund_Name",
    "distribution_date",
    "distribution_capitalCost",
    "distribution_gainLoss",
    "distribution_other",
    "distribution_distofCapital",
    "distribution_interest",
    "distribution_dividends",
    "distribution_total",
    "fund_ID",
    "distribution_ID",
    "LP_name",
    "distribution_LPname_total",
  ];

  const getRefData = async (fundName: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/getDistributionByID/" + dat + "/" + fundName
      );

      if (response.data.data.length === 0) {
        setMessage("No data found for this company");
      } else setMessage("");
      const responseData = await response.data.data[0];

      const UpdatedData: any = Object.keys(staticData)
        .filter((key) => keys.includes(key))
        .reduce((obj: any, key) => {
          if (
            responseData.hasOwnProperty(key) &&
            responseData[key] !== undefined
          ) {
            obj[key] = responseData[key];
          } else {
            obj[key] = staticData[key]; // Keep the original value if not updating
          }
          return obj;
        }, {});

      UpdatedData != null ? setIsSelected(true) : setIsSelected(false);
      setStaticData(UpdatedData);
    } catch (error) {
      console.error(error);
    }
  };
  const getcurrentData = async (id: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/getDistributionCurrentData/" + dat + "/" + id
      );
      const responseData = await response.data.data;
      response.data.data[0] != null
        ? setInputValue(responseData[0])
        : setInputValue({
            Fund_Name: selectedFundName,
            distribution_date: dat,
            distribution_capitalCost: "",
            distribution_gainLoss: "",
            distribution_other: "",
            distribution_distofCapital: "",
            distribution_interest: "",
            distribution_dividends: "",
            distribution_total: "",
            distribution_ID: "",
            investor: "",
            distribution_carried: "",
            distribution_datefund_ID: "",
            distribution_amount: "",
            fund_ID: selectedFundId,
            distribution_LPname_total: 0,
            LP_name: "",
          });

      if (response.data.data.length === 0) {
        // setInputValue({});
        setIsUpdate(false);
      } else {
        // setInputValue(responseData[0]);
        setIsUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFileNames = async () => {
    try {
      const response = await axiosInstance.get("/extractedDistributionName");
      const responseData = await response.data.data;
      responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      setFileName(responseData);
      const fundDateMapping: { [key: string]: string } = {};
      responseData.forEach((data: any) => {
        fundDateMapping[data.Fund_Name] = data.distribution_date;
      });
      setKeyMap(fundDateMapping);
      if (fundID) {
        setSelectedFundId(fundID);
        const selectedFund = responseData.find(
          (fund: any) => fund.fund_ID === fundID
        );
        setSelectedFundName(selectedFund.Fund_Name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLPNames = async () => {
    try {
      const response = await axiosInstance.get("/LP_names");
      const responseData = await response.data.data;
      const LP_list: any = [];
      responseData.forEach((data: any) => {
        LP_list.push(data.LP_name);
      });
      setLPNames(LP_list);
    } catch (error) {
      console.error(error);
    }
  };
  const copyToClipboardAndPaste = (key: any, value: any) => {
    if (typeof value === "string" && value.includes(",")) {
      const cleanedValue = value.replace(/,/g, "");
      const parsedValue = parseFloat(cleanedValue);
      if (!isNaN(parsedValue)) {
        value = parsedValue;
      }
    }
    setInputValue((prevInputValue: any) => ({
      ...prevInputValue,
      [key]: value,
    }));
  };
  const [keyOrder, setKeyOrder] = useState<string[]>([
    "LP_name",
    "Fund_Name",
    "distribution_date",
    "distribution_capitalCost",
    "distribution_gainLoss",
    "distribution_other",
    "distribution_distofCapital",
    "distribution_interest",
    "distribution_dividends",
    "distribution_total",
    "distribution_ID",
    "investor",
    "distribution_carried",
    "distribution_datefund_ID",
    "distribution_amount",
    "distribution_LPname_total",
  ]);
  const constKey = ["Fund_Name", "cc_limitedPartner", "cc_ID"];
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/workflow"); // Navigate to the /workflow page
  };
  const [staticData, setStaticData]: any = useState({
    Fund_Name: "",
    distribution_date: "",
    distribution_capitalCost: 0,
    distribution_gainLoss: 0,
    distribution_other: 0,
    distribution_distofCapital: 0,
    distribution_interest: 0,
    distribution_dividends: 0,
    distribution_total: 0,
    fund_ID: "",
    distribution_ID: "",
    investor: "",
    distribution_carried: "",
    distribution_datefund_ID: "",
    distribution_amount: "",
    distribution_LPname_total: 0,
    LP_name: "",
  });
  const [LPNames, setLPNames] = useState<any[]>([]);
  const handleButtonClick = () => {
    const distributionDate = inputvalue["distribution_date"];

    // Regular expression to match the date format dd-mm-yyyy
    const dateRegex = /^([0-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;

    if (!dateRegex.test(distributionDate)) {
      toast.error("Please enter a valid date in the format dd-mm-yyyy");
      return; // Stop further execution if the validation fails
    }

    updateFileData(); // Call your function to update or add data
  };
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div style={{ display: "flex" }}>
                {/* {LPNames.length > 0 && LPNames.map(data => (
                  <div key={data}>
                    {data}
                  </div>
                ))} */}
                <label
                  style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Select Fund :
                </label>
                <select
                  style={{
                    backgroundColor: "#f2f2f2",
                    marginRight: "20px",
                    color: "#333",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    width: "250px",
                    cursor: "pointer",
                  }}
                  value={selectedFundId}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setCurrentRunID("");
                    setRunIDs([]);
                    setInputValue(null);
                    const selectedIndex = e.target.selectedIndex - 1;
                    if (selectedIndex >= 0) {
                      const selectedFund = filename.find(
                        (fund) => fund.fund_ID === e.target.value
                      );
                      setSelectedFundId(e.target.value);
                      setSelectedFundName(selectedFund?.Fund_Name || "");
                      if (selectedFund?.Fund_Name) {
                        const date = convertStringToDate(
                          keyMap[selectedFund.Fund_Name]
                        );
                        setDate(date);
                      }
                    } else {
                      setSelectedFundId("");
                    }
                  }}
                >
                  <option value="">please Select Fund</option>
                  {filename.map((fileName, index) => {
                    return (
                      <option
                        key={filename[index].Fund_Name}
                        value={filename[index].fund_ID}
                        data-fund-name={filename[index].fund_ID}
                      >
                        {filename[index].Fund_Name}
                      </option>
                    );
                  })}
                </select>
                <div>
                  <DatePicker
                    label="Distribution Date"
                    format="dd/MM/yyyy"
                    value={date}
                    onChange={(newValue) => {
                      setCurrentRunID("");
                      setInputValue(null);
                      setDate(newValue);
                    }}
                  />

                  <button
                    onClick={() => {
                      if (date && selectedFundId) {
                        // setInputValue({});
                        setIsSelected(false);
                        getData(selectedFundId);
                        getRefData(selectedFundId);
                        getcurrentData(selectedFundId);
                        setCurrentRunID("");
                      } else {
                        alert("Please select Fund and date");
                      }
                    }}
                    type="submit"
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      padding: "12px 24px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "24px",
                      cursor: "pointer",
                    }}
                  >
                    Get data
                  </button>
                </div>
                <Button
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  style={{
                    marginLeft: "auto", // This will push the button to the end
                    padding: "12px 24px",
                    backgroundColor: "#007bff",
                    borderRadius: "24px",
                  }}
                >
                  Back
                </Button>
              </div>

              <div style={{ marginTop: "30px" }}>
                {selectedFundId && inputvalue && (
                  <>
                    <label
                      style={{
                        marginRight: "10px",
                        marginTop: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Select Run ID :
                    </label>

                    <select
                      style={{
                        backgroundColor: "#f2f2f2",
                        marginLeft: "30px",
                        color: "#333",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        outline: "none",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                        width: "280px",
                        height: "52px",
                        cursor: "pointer",
                      }}
                      value={currentRunID} // Bind the value to currentRunID state
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const selectedIndex = e.target.selectedIndex - 1;
                        if (selectedIndex >= 0) {
                          const selectedID = runIDs.find(
                            (id) => id.run_id === e.target.value
                          );
                          setCurrentRunID(selectedID?.run_id || ""); // Set currentRunID state
                          getData(selectedFundId, selectedID?.run_id); // Fetch data with selected runID
                        } else {
                          setCurrentRunID(""); // Reset currentRunID when no valid option is selected
                          getData(selectedFundId); // Fetch data without runID
                        }
                      }}
                    >
                      <option value="">Please select Run ID</option>
                      {runIDs.map((runID, index) => (
                        <option key={index} value={runID.run_id}>
                          {runID.run_id}
                        </option>
                      ))}
                    </select>
                  </>
                )}
                {currentRunID !== "" && fundPDF !== "" && (
                  <>
                    <a
                      href={fundPDF}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginLeft: "20px",
                        color: "#0066cc",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      View Source PDF
                    </a>
                  </>
                )}
              </div>

              {isSelected && inputvalue && (
                <div>
                  <div style={styles.buttonContainer}>
                    <button
                      onClick={
                        handleButtonClick
                        // updateFileData();
                      }
                      type="button"
                      style={styles.button}
                    >
                      {isUpdate ? "Update" : "Add"}
                    </button>
                  </div>
                  <form
                    ref={form}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div>
                      <table
                        style={{
                          textAlign: "left",
                          width: "100%",
                          borderCollapse: "separate", // Allows the borders to be separated for spacing
                          borderSpacing: "1px", // Sets the space between the cells
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                ...styles.tableLabel,
                                width: "15%", // Set width to 25%
                              }}
                            >
                              Key name
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "35%", // Set width to 25%
                              }}
                            >
                              Update value manually
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "25%", // Set width to 25%
                              }}
                            >
                              AI extracted value
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                color: "grey",
                                textAlign: "center",
                                width: "25%", // Set width to 25%
                              }}
                            >
                              Previous Entry
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(staticData)
                            .filter(
                              (key) =>
                                key !== "_id" &&
                                key !== "fund_ID" &&
                                key !== "company_ID" &&
                                key !== "portfolio_companyName" &&
                                key !== "distribution_dateKey" &&
                                key !== "distribution ID" &&
                                !key.includes("pageNumber")
                            )
                            .sort(
                              (a, b) =>
                                keyOrder.indexOf(a) - keyOrder.indexOf(b)
                            )
                            .map((key, index) => {
                              const file = extractedData as {
                                [key: string]: any;
                              };
                              const refrence = staticData as {
                                [key: string]: any;
                              };
                              if (key === "_id") return null;
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    paddingTop: "30px",
                                    borderBottom: "1px solid #cccccc",
                                    backgroundColor:
                                      index % 2 === 0 ? "#f2f2f2" : "#FFFFFF",
                                  }}
                                >
                                  <td
                                    style={{
                                      ...styles.label,
                                      paddingBottom: "10px",
                                      width: "15%",
                                    }}
                                  >
                                    {key.replace(/_/g, " ")}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                    }}
                                  >
                                    {key !== "LP_name" ? (
                                      <TextField
                                        multiline
                                        minRows={1}
                                        maxRows={4}
                                        id={index.toString()}
                                        name={key}
                                        style={{
                                          width: "100%", // Make input take full width of its cell
                                          textAlign: "justify",
                                          // marginBottom: "10px",
                                          background: "#fff", // Adds a white background
                                          borderColor: "#007bff", // Blue border color
                                          borderWidth: "2px", // Border width
                                          borderRadius: "4px", // Rounded corners
                                        }}
                                        value={
                                          inputvalue != null
                                            ? inputvalue[key]
                                            : ""
                                        }
                                        type="text"
                                        onChange={(e) => {
                                          setInputValue(
                                            (prevInputValue: any) => ({
                                              ...prevInputValue,
                                              [key]: e.target.value,
                                            })
                                          );
                                        }}
                                        disabled={constKey.includes(key)}
                                      />
                                    ) : (
                                      <select
                                        id={index.toString()}
                                        name={key}
                                        style={{
                                          width: "100%", // Full width
                                          textAlign: "justify",
                                          background: "#f0f4f7", // Light background color
                                          borderColor: "black", // Blue border color
                                          borderWidth: "2px", // Border width
                                          borderRadius: "4px", // Rounded corners
                                          height: "100%",
                                          padding: "8px", // Padding inside the dropdown
                                          color: "#333", // Text color
                                          fontSize: "14px", // Font size
                                          outline: "none", // Remove default outline
                                          appearance: "none", // Remove default arrow for custom styling
                                          backgroundImage:
                                            "url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2210%22 height=%225%22 viewBox=%220 0 10 5%22><path fill=%22%23007bff%22 d=%22M0 0l5 5 5-5z%22/></svg>')", // Custom dropdown arrow
                                          backgroundRepeat: "no-repeat", // No repeat of the background image
                                          backgroundPositionX:
                                            "calc(100% - 10px)", // Position the arrow on the right
                                          backgroundPositionY: "center", // Center the arrow vertically
                                        }}
                                        value={
                                          inputvalue != null
                                            ? inputvalue[key]
                                            : ""
                                        }
                                        onChange={(e) => {
                                          setInputValue(
                                            (prevInputValue: any) => ({
                                              ...prevInputValue,
                                              [key]: e.target.value,
                                            })
                                          );
                                        }}
                                        disabled={constKey.includes(key)}
                                      >
                                        <option value="" disabled>
                                          Select an LP
                                        </option>{" "}
                                        {/* Placeholder option */}
                                        {LPNames.map((lp, idx) => (
                                          <option key={idx} value={lp}>
                                            {lp}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      ...styles.label,
                                      textAlign: "center",
                                      paddingBottom: "10px",
                                      width: "25%", // Set width to 25%
                                    }}
                                  >
                                    {file && file[key] && currentRunID
                                      ? file[key]
                                      : ""}
                                    {file &&
                                      file[key] &&
                                      currentRunID &&
                                      !constKey.includes(key) && (
                                        <IconButton
                                          aria-label="content copy"
                                          size="small"
                                          onClick={() =>
                                            copyToClipboardAndPaste(
                                              key,
                                              file[key]
                                            )
                                          }
                                        >
                                          <ContentCopyIcon fontSize="inherit" />
                                        </IconButton>
                                      )}
                                  </td>
                                  <td
                                    style={{
                                      ...styles.label,
                                      textAlign: "center",
                                      color: "grey",
                                      paddingBottom: "10px",
                                      width: "25%", // Set width to 25%
                                    }}
                                  >
                                    {refrence[key]}
                                    {refrence[key] !== "" &&
                                      !constKey.includes(key) && (
                                        <IconButton
                                          aria-label="content copy"
                                          size="small"
                                          onClick={() =>
                                            copyToClipboardAndPaste(
                                              key,
                                              refrence[key]
                                            )
                                          }
                                        >
                                          <ContentCopyIcon fontSize="inherit" />
                                        </IconButton>
                                      )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
              )}

              {message !== "" && (
                <Typography mt={10} variant="subtitle2" color="textPrimary">
                  {message}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DistributionUpdateForm;
