import React from "react";
import ReactModal from "react-modal-resizable-draggable";
import "../../Style/flexible-modal.css";

interface PdfViewerModalProps {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string;
  pageNumber: number; // Add pageNumber as a prop
}

const Modal = ReactModal as any;

const PdfViewerModal: React.FC<PdfViewerModalProps> = ({
  isOpen,
  onClose,
  pdfUrl,
  pageNumber,
}) => {
  // Append the page number to the PDF URL
  const pdfUrlWithPage = `${pdfUrl}#page=${pageNumber}&toolbar=0`;

  return (
    <Modal
      initWidth={"50%"}
      intiHeight={"80%"}
      isOpen={isOpen}
      onRequestClose={onClose}
      disableResize={true}
      top={100}
      left={100}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <button
          onClick={onClose}
          style={{
            alignSelf: "flex-end",
            marginBottom: "10px",
            marginTop: "70px",
            marginRight: "10px",
          }}
        >
          Close
        </button>
        <div style={{ flex: 1, height: "100%" }}>
          <iframe
            src={pdfUrlWithPage}
            title="PDF Viewer"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PdfViewerModal;
