import React, { useRef, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Collapse,
  Divider,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";

interface FileUploadProps {
  acceptedFileTypes?: string; // MIME types or extensions (e.g., "application/pdf" or ".pdf")
  multiple?: boolean; // Whether to allow multiple file uploads
  onFilesSelected: (files: File[]) => void; // Callback when files are selected
  files: File[]; // Files to display
  onFileDelete: (index: number) => void; // Callback to handle file deletion
}

const MultipleFileUpload: React.FC<FileUploadProps> = ({
  acceptedFileTypes = "application/pdf",
  multiple = true,
  onFilesSelected,
  files,
  onFileDelete,
}) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const validateFiles = (selectedFiles: File[]): File[] => {
    const validFiles = selectedFiles.filter((file) => {
      const acceptedExtensions = acceptedFileTypes
        .split(",")
        .map((ext) => ext.trim());
      const isValid = acceptedExtensions.some(
        (type) => file.type === type || file.name.endsWith(type)
      );
      if (!isValid) {
        toast.error(`File type not supported: ${file.name}`);
      }
      return isValid;
    });
    return validFiles;
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files) as File[];
    const validFiles = validateFiles(droppedFiles);
    onFilesSelected(validFiles);
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files) as File[];
      const validFiles = validateFiles(selectedFiles);
      onFilesSelected(validFiles);
    }
  };

  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  return (
    <>
      <Box
        onDragOver={(event) => event.preventDefault()}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={() => inputFileRef.current?.click()}
        sx={{
          marginTop: "20px",
          width: "100%",
          justifyContent: "center",
          padding: "20px",
          cursor: "pointer",
          textAlign: "center",
          border: isDragging ? "2px dashed #1976d2" : "2px dashed gray",
          backgroundColor: isDragging ? "#e3f2fd" : "transparent",
          transition: "background-color 0.3s, border-color 0.3s",
        }}
      >
        <CloudUploadIcon
          sx={{ fontSize: 50, color: isDragging ? "#1976d2" : "gray" }}
        />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {files.length > 0
            ? `${files.length} PDF${files.length > 1 ? "s" : ""} selected`
            : "Drag & Drop or Click to Select Files"}
        </Typography>
        <input
          type="file"
          accept={acceptedFileTypes}
          style={{ display: "none" }}
          onChange={onFileChange}
          ref={inputFileRef}
          multiple={multiple}
        />
      </Box>

      <List sx={{ marginTop: "10px" }}>
        {files.map((file, index) => (
          <Collapse key={index} in={true} timeout="auto" unmountOnExit>
            <ListItem
              sx={{
                mb: 1, // Adds margin bottom
                bgcolor: index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternating background colors
                borderRadius: 1, // Adds slight rounding to the corners
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onFileDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <InsertDriveFileIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={file.name}
                primaryTypographyProps={{ fontWeight: "medium" }}
              />
            </ListItem>
          </Collapse>
        ))}
      </List>
    </>
  );
};

export default MultipleFileUpload;
