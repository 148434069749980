import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  extrcatedData,
  FundTableProps,
} from "../../../interfaces/keyMetricsTable";

const StyledTableContainer = styled(TableContainer)`
  max-height: 500px;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 6px; /* width of the vertical scrollbar */
    height: 6px; /* height of the horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background: #888; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scroll thumb on hover */
  }

  /* For Firefox */
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
`;

interface TableHeadingProps {
  children: React.ReactNode;
  isFirstColumn?: boolean;
  colSpan?: number;
  addSeparator?: boolean;
  top?: number;
}

interface KeyMapping {
  [key: string]: string;
}

const TableHeading: React.FC<TableHeadingProps> = ({
  children,
  isFirstColumn,
  colSpan,
  addSeparator,
  top = 0,
}) => {
  return (
    <TableCell
      colSpan={colSpan}
      style={{
        borderBottom: 0,
        borderRight: addSeparator ? "2px solid #ddd" : "none",
        textAlign: isFirstColumn ? "left" : "center",
        backgroundColor: "#351773",
        color: "white",
        fontWeight: "bolder",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        justifyContent: "center",
        alignItems: "center",
        position: "sticky",
        top: top,
      }}
    >
      {children}
    </TableCell>
  );
};

const KeyMetricsTable: React.FC<FundTableProps> = (props) => {
  const [TableData, setTableData] = React.useState<extrcatedData[]>(props.data);
  const keyMapping: KeyMapping = {
    portfolio_name: "Portfolio Name",
    percentage_ownership: "%FDO",
    reserved_capital: "Reserved Capital",
    revenue: "Revenue",
    ttm_as_of: "TTM",
    cash: "Cash",
    estimated_runway: "Estimated Runway",
    quebec_employees: "QC",
    roc_employees: "ROC",
    outside_canada_employees: "Outside Canada",
  };

  const renderTableHeader = () => {
    const firstRow = props.data[0];
    return (
      <TableHead>
        <TableRow>
          <TableHeading colSpan={3} addSeparator top={0}>
            {" "}
          </TableHeading>
          <TableHeading colSpan={3} addSeparator top={0}>
            Current FY YTD
          </TableHeading>
          <TableHeading colSpan={2} addSeparator isFirstColumn top={0}>
            {" "}
          </TableHeading>
          <TableHeading colSpan={3} top={0}>
            Number of Employees
          </TableHeading>
        </TableRow>
        <TableRow>
          {Object.keys(firstRow)
            .filter(
              (key) =>
                key !== "LP_name" && key !== "Fund_Name" && key !== "fund_ID"
            )
            .map((heading, idx) => (
              <TableHeading key={idx} isFirstColumn={idx === 0} top={35}>
                {keyMapping[heading] || heading}
              </TableHeading>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleInputChange = (rowIndex: number, key: string, value: string) => {
    setTableData((prevData) =>
      prevData.map((row, idx) =>
        idx === rowIndex ? { ...row, [key]: value } : row
      )
    );
  };

  const RenderTableContent = () => {
    let documentData = TableData; // Use the state variable for current data
    return (
      <TableBody>
        {documentData.map((item, idx) => (
          <TableRow key={idx}>
            {Object.entries(item)
              .filter(
                ([key]) =>
                  key !== "LP_name" && key !== "Fund_Name" && key !== "fund_ID"
              )
              .map(([key, content], index) => {
                const displayContent =
                  typeof content === "number"
                    ? content.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                      })
                    : content === ""
                    ? ""
                    : content;
                return (
                  <TableCell
                    key={index}
                    style={{
                      backgroundColor:
                        idx % 2 === 0 ? "rgba(217, 217, 217, 0.2)" : "#FFFFFF",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100px",
                      textAlign: index === 0 ? "left" : "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="standard"
                      value={
                        displayContent != null ? displayContent.toString() : ""
                      }
                      onChange={(e) =>
                        handleInputChange(idx, key, e.target.value)
                      }
                      InputProps={{
                        style: {
                          textAlign: "center",
                          alignItems: "center",
                          display: "flex",
                        },
                      }}
                    />
                  </TableCell>
                );
              })}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <div>
      <Paper style={{ width: "100%" }}>
        <StyledTableContainer
          className="tableContainer"
          style={{ overflow: "auto" }}
        >
          <Table stickyHeader>
            {renderTableHeader()}
            {RenderTableContent()}
          </Table>
        </StyledTableContainer>
      </Paper>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          paddingBottom: "30px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => props.handleDataUpdate(TableData)}
          style={{
            margin: "5px",
            marginLeft: "20px",
            backgroundColor: "#007bff",
            borderRadius: "18px",
          }}
          variant="contained"
        >
          Add Data
        </Button>
      </div>
    </div>
  );
};

export default KeyMetricsTable;
