import { parse } from "date-fns";

export const convertStringToDate = (dateStr: string): Date => {
  return parse(dateStr, "dd-MM-yyyy", new Date());
};
export const convertToDateFormat = (dateString: Date) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
};
export const generateYears = (startYear: number): number[] => {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years.reverse();
};
export const getPreviousQuarterEndDate = (dateString: string) => {
  const [day, month, year] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  let previousQuarterEndMonth;
  let previousQuarterEndYear = date.getFullYear();

  if (month >= 1 && month <= 3) {
    previousQuarterEndMonth = 12;
    previousQuarterEndYear -= 1; // Previous year
  } else if (month >= 4 && month <= 6) {
    previousQuarterEndMonth = 3;
  } else if (month >= 7 && month <= 9) {
    previousQuarterEndMonth = 6;
  } else {
    previousQuarterEndMonth = 9;
  }

  const previousQuarterEndDate = new Date(
    previousQuarterEndYear,
    previousQuarterEndMonth,
    0
  );
  const endDay = String(previousQuarterEndDate.getDate()).padStart(2, "0");
  const endMonth = String(previousQuarterEndDate.getMonth() + 1).padStart(
    2,
    "0"
  );
  const endYear = previousQuarterEndDate.getFullYear();

  const formattedEndDate = `${endDay}-${endMonth}-${endYear}`;

  return formattedEndDate;
};
export function formatDateToQuarter(dateString: string): string {
  const [day, month, year] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  if (isNaN(date.getTime())) {
    return "-";
  }
  const quarter = Math.floor((date.getMonth() + 3) / 3);
  return `Q${quarter} ${year}`;
}

export const sampleOutput = `<html>
<head>
</head>
<body>
<h2>Analysis Report</h2>
<p>No updates to be made for <strong>New Investments</strong> and <strong>Fundraising and LP Relations</strong>.</p>

<h3>Valuation</h3>
<p>The total Net Asset Value (NAV) of EnerTech Capital Partners IV Fund as of June 30, 2023, is $19,110,486, with a cost basis of $24,614,847. This represents a decrease in the NAV from the previous period, which was not explicitly mentioned in the provided text. However, the fund had a net decrease in valuation of $0.3 million for the second quarter, primarily due to a $0.5 million decrease from Encycle based on an offer to purchase the company, while Powerside increased by $0.2 million based on currency adjustment.</p>

<h3>Portfolio Updates</h3>
<ul>
<li><strong>Encycle:</strong> Encycle is anticipated to run out of cash by the end of the third quarter of 2023, with the management exploring options to extend its runway. A materially negative financial outcome from the investment is expected.</li>
<li><strong>GeoDigital:</strong> GeoDigital faced challenges during the quarter due to wildfire smoke and helicopter maintenance but balanced out the second quarter's miss with strong first-quarter performance. It booked a record amount of $15.4 million for a quarter.</li>
<li><strong>Powerside:</strong> Powerside received two LOIs, one for investment and another for the purchase of a business unit. The board is inclined to proceed with the investment offer, which includes a $20 million investment expected to close in the fourth quarter.</li>
</ul>

<h3>Exits</h3>
<p>During the second quarter, IP Keys Power Partners was acquired by Parsons Corporation in a cash transaction. The Fund received $4.3 million at closing with an additional $0.3 million held in escrow.</p>

<h3>Challenges and Mitigations</h3>
<ul>
<li><strong>Encycle:</strong> The company received an extremely low offer for acquisition and is facing imminent cash shortages. The current investors are unlikely to continue funding.</li>
<li><strong>GeoDigital:</strong> The company experienced operational challenges due to external environmental factors but has already taken steps to improve its IT resiliency and equipment.</li>     
<li><strong>Powerside:</strong> Powerside's business was impacted by a slowdown in the semiconductor market, affecting the M&A process and overall financial results.</li>
</ul>

<h3>Fund Strategy Adjustments</h3>
<p>No updates to be made.</p>

<h3>Market Outlook</h3>
<p>The management's perspective on market conditions has not been explicitly mentioned in the provided text.</p>

<h3>Action Items</h3>
<ul>
<li>Consider the potential impact of the semiconductor market slowdown on Powerside's future performance and M&A opportunities.</li>
<li>Monitor Encycle's cash flow situation and management's efforts to extend the company's runway.</li>
<li>Assess the implications of GeoDigital's record bookings and how it contributes to the overall valuation of the Fund.</li>
</ul>
</body>`;
