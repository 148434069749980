import { useState, useEffect } from "react";
import axiosInstance from "../../../../utils/axios/axios.instance";
import {
  FormControl,
  Grid,
  Divider as MuiDivider,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import CustomProgressBar from "../../../../components/CustomProgressBar";
import Modal from "../../../../components/Workflow/ModalForAIExtraction";
import DuplicateModal from "../../../../components/Workflow/ModalDuplicateFile";
import MultipleFileUpload from "../../../../components/MultiFileUpload/multifileUPload";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AddExtraction() {
  const { user } = useAuth0();
  const [userEmail, setUserEmail] = useState("");
  const [userText, setuserText] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [fundID, setfundID] = useState("");
  const [fundKeys, setFundKeys] = useState<{ [key: string]: any }>({});
  const [fund_name, setFund_name] = useState("");
  const [fundIDs, setfundIDs] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  // const [showModal2, setShowModal2] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [fileName, setFileName] = useState("");

  const quarters = [
    { name: "Q1", value: "03-31" },
    { name: "Q2", value: "06-30" },
    { name: "Q3", value: "09-30" },
    { name: "Q4", value: "12-31" },
  ];

  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names");
    const responseData = response.data.data;
    const sortedData = responseData.sort(
      (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
        return a.Fund_Name.toLowerCase().localeCompare(
          b.Fund_Name.toLowerCase()
        );
      }
    );
    setfundIDs(sortedData);
    setFund_name(sortedData[0]["Fund_Name"]);
    setfundID(sortedData[0]["fund_ID"]);
    let keys: { [key: string]: any } = {};
    responseData.forEach((fund: { [x: string]: any }) => {
      keys[fund["fund_ID"]] = fund["Fund_Name"];
    });
    setFundKeys(keys);
  };

  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    getFundNames();
    setCurrentDate();
  }, [user?.email]);

  const setCurrentDate = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    let currentQuarter = "";
    let currentYear = currentDate.getFullYear();

    if (currentMonth >= 1 && currentMonth <= 3) {
      currentQuarter = "12-31";
      currentYear = currentYear - 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      currentQuarter = "03-31";
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      currentQuarter = "06-30";
    } else {
      currentQuarter = "09-30";
    }
    setSelectedQuarter(currentQuarter);
    setSelectedYear(currentYear.toString());
  };

  const resetData = () => {
    setIsButtonDisabled(false);
    setFiles([]);
    setuserText("");
  };
  const handleFilesChange = (files: File[]) => {
    setFiles(files);
  };

  const runscript = async () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 4000);

    if (files.length > 0) {
      let time = new Date().toISOString().replace(/:/g, "-");

      try {
        const formData = new FormData();

        // Send files under a single key "pdf" as expected by the backend
        files.forEach((currentFile) => {
          formData.append("pdf", currentFile);
        });
        formData.append("timestamp", time);
        formData.append("fund_ID", fundID);
        formData.append("file_name", files.map((f) => f.name).join(", "));
        formData.append("email", userEmail);
        formData.append("UserNotes", userText);
        formData.append("date", `${selectedYear}-${selectedQuarter}`);

        await axiosInstance
          .post("/fund-portfolio-pdf-extraction", formData, {
            headers: { "Content-Type": "multipart/form-data" },
            params: { timestamp: time },
          })
          .then((resp) => {
            if (resp.data.data === "Duplicate file.") {
              setFileName(resp.data.azureURL.data);
              toast.info(
                "The same file already exists in our system. We will process the existing file to ensure continuity and avoid duplication."
              );
            }
            setShowModal(true);
          });
      } catch (err) {
        toast.error("Error Processing File. Please try again later.");
      }
    } else {
      toast.error("Please select at least one file to process");
    }
  };

  const uploadAnother = () => {
    resetData();
    getFundNames();
    setCurrentDate();
    setShowModal(false);
  };

  const moveToWorkflow = () => {
    navigate("/workFlow");
    setShowModal(false);
  };

  const moveToNextPage = () => {
    navigate("/update_script_data");
    setShowModal(false);
  };

  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years.reverse();
  };

  const handleQuarterChange = (event: any) => {
    setSelectedQuarter(event.target.value);
  };

  const handleYearChange = (event: any) => {
    setSelectedYear(event.target.value);
  };

  const handleFundChange = (event: any) => {
    setFund_name(fundKeys[event.target.value]);
    setfundID(event.target.value);
  };
  const handleFilesSelected = (newFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      <Helmet title="AI Data Extraction" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Quarterly Report
      </Typography>

      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            WorkFlow
          </Link>
          <Typography>Add Report</Typography>
        </Breadcrumbs>

        <CustomProgressBar progress={50} />
      </div>

      <Divider my={6} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{ padding: "10px" }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fundID}
                  style={{ maxWidth: "300px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundIDs.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{ padding: "10px" }}
              >
                Select reporting quarter and year:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      label="Quarter"
                    >
                      {quarters.map((quarter) => (
                        <MenuItem key={quarter.name} value={quarter.value}>
                          {quarter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* File Upload Component */}
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <MultipleFileUpload
              acceptedFileTypes="application/pdf"
              multiple={true}
              files={files}
              onFilesSelected={handleFilesSelected}
              onFileDelete={handleFileDelete}
            />
            {/* <FileUploadComponent
              onFilesChange={handleFilesChange}
              resetTrigger={resetTrigger}
            /> */}
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <TextField
              fullWidth
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              type="email"
              label="Email Address"
              variant="outlined"
              placeholder="email@example.com"
            />
            <TextField
              multiline
              minRows={4}
              maxRows={8}
              fullWidth
              value={userText}
              onChange={(e) => setuserText(e.target.value)}
              style={{ marginTop: "15px" }}
              type="email"
              label="Notes (Optional)"
              variant="outlined"
              placeholder="Add any notes here you wish to include in the report"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              disabled={isButtonDisabled}
              onClick={() => {
                if (selectedQuarter && selectedYear) {
                  runscript();
                } else {
                  toast.error("Please select a quarter and year");
                }
              }}
              style={{
                backgroundColor: isButtonDisabled ? "#9E9E9E" : "#007bff",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "18px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                cursor: isButtonDisabled ? "default" : "pointer",
                marginRight: "10px",
              }}
            >
              Process
            </button>
            <button
              onClick={resetData}
              style={{
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "18px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <Modal
          email={userEmail}
          handleClose={uploadAnother}
          fundName={fund_name}
          fileName={fileName}
          show={showModal}
          handleUploadAnother={uploadAnother}
          handleMoveToWorkflow={moveToWorkflow}
          handleNextPage={moveToNextPage}
          isPortfolio={false}
        />

        <DuplicateModal
          handleClose={resetData}
          fileName={fileName}
          show={false}
          sameFile={resetData}
        />
      </div>
    </div>
  );
}

export default AddExtraction;
