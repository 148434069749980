import { useState } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import { FundQtrProps, IFundQtr } from "../../interfaces/fundQtr.interface";
import { styles } from "./../../Style/fundQtr";
import DynamicForm from "./Add_updateForm";
import { toast } from "react-toastify";
import { fundQtrKeys } from "../HelperFunctions/addDataForm";
import ActionDialog from "./datadialog";
function FundQtr({ date, id }: FundQtrProps) {
  const [fieldData, setFieldData] = useState({});
  const [newData, setNewData] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isrefrenceData, setIsrefrenceData] = useState(false);

  function createFieldData(responseData: IFundQtr): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date | Number;
    };
  } {
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date | Number;
      };
    } = {};

    Object.keys(responseData).forEach((key) => {
      if (
        key === "_id" ||
        key === "ReportingDateKey" ||
        key === "ReportingDateString"
      ) {
        // Exclude specific fields from being added to fieldData
      } else {
        if (key === "Reporting_Date") {
          // Check if the field name is the date field you want to set as "type: date"
          fieldData[key] = {
            type: "date",
            defaultValue: responseData[key as keyof IFundQtr] as Date,
          };
        } else {
          const value = responseData[key as keyof IFundQtr];
          if (typeof value === "number") {
            fieldData[key] = {
              type: "number",
              defaultValue: value,
            };
          } else {
            fieldData[key] = {
              type: "text",
              defaultValue: value as string | undefined,
            };
          }
        }
      }
    });
    return fieldData;
  }
  const validation = async () => {
    const validation = await axiosInstance.get(
      "/getFundQtr/" + date + "/" + id
    );
    if (validation.data.data.length !== 0) {
      toast.error("Data already exists for the given date please update it");
      return;
    }
    setShowDialog(true);
  };
  const fetchData2 = async () => {
    setShowDialog(false);
    setUpdateMessage("add");
    setFieldData({});
    setNewData(true);
    try {
      const response = await axiosInstance.get(
        "/fund-qtr-sample/" + date + "/" + id
      );
      if (response.data === "error") {
        toast.error("Error while fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          toast.error("There is no data with the given Date");
          setFieldData({});
        } else {
          const responseData = response.data.data[0]; // Assuming this is your response data
          setFieldData(createFieldData(responseData));
        }
        // Handle success
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };
  const [qtrRespData, setQtrRespData] = useState<any>({});
  const [updateMessage, setUpdateMessage] = useState("");
  // Define an async function inside useEffect
  const fetchData = async () => {
    setFieldData({});
    setUpdateMessage("update");
    setNewData(false);
    if (date == null || date === "" || id == null || id === "") {
      toast.error("Please select a fund ID and a date");
      return;
    } else {
      try {
        const response = await axiosInstance.get(
          "/getFundQtr/" + date + "/" + id
        );
        if (response.data === "error") {
          toast.error("Error while fetching Data.");
        } else {
          if (response.data.data.length === 0) {
            toast.error("There is no data with the given Date");
            setFieldData({});
          } else {
            const responseData = response.data.data[0]; // Assuming this is your response data
            setQtrRespData(responseData);
            setFieldData(createFieldData(responseData));
          }

          // Handle success
        }
      } catch (err) {
        toast.error("Error while fetching Data.");
        console.error(err);
      }
    }
  };
  async function handleChangeData(
    formData: Record<string, string>,
    updateType: string
  ) {
    if (updateType !== "add" && updateType !== "update") {
      toast.error("Invalid update type");
      return;
    }
    try {
      let response;
      if (updateType === "add") {
        formData.fund_ID = id;
        response = await axiosInstance.post("/add_fundQtrNormal", { formData });
      } else {
        const updatedFormData = { ...formData };
        let uploadQtrRespData = { ...qtrRespData };
        Object.keys(updatedFormData).forEach((key) => {
          // eslint-disable-next-line eqeqeq
          if (
            key === "Reporting_Date" ||
            key === "fund_ID" ||
            key === "ReportingDateKey"
          )
            return;
          uploadQtrRespData[key] = updatedFormData[key as keyof IFundQtr];
        });
        response = await axiosInstance.patch("/update_fundQtrNormal", {
          fund_ID: id,
          Reporting_Date: date,
          formData: uploadQtrRespData,
        });
      }

      if (response.data === "error") {
        toast.error("Error occurred while processing data");
      } else {
        toast.success(
          `Data successfully ${updateType === "add" ? "added" : "updated"}`
        );

        setFieldData({});
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while processing data");
    }
  }
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={styles.buttonContainer}>
          <button type="submit" style={styles.button} onClick={fetchData}>
            Get data
          </button>
        </div>
        <div style={styles.buttonContainer}>
          <button
            type="submit"
            style={styles.addbutton}
            onClick={() => {
              validation();
            }}
          >
            Add new Data
          </button>
        </div>
      </div>
      {Object.keys(fieldData).length !== 0 && (
        <>
          {updateMessage === "update" ? (
            <DynamicForm
              fieldData={fieldData}
              changedata={handleChangeData}
              staticData={true}
              newData={newData}
              reportingDate=""
              orderKeys={fundQtrKeys}
            />
          ) : (
            <DynamicForm
              fieldData={fieldData}
              changedata={handleChangeData}
              staticData={false}
              newData={newData}
              reportingDate={date}
              orderKeys={fundQtrKeys}
              refrenceData={isrefrenceData}
            />
          )}
        </>
      )}
      <ActionDialog
        showDialog={showDialog}
        onClose={() => setShowDialog(false)}
        action1onClick={() => {
          setIsrefrenceData(false);
          fetchData2();
        }}
        action2onClick={() => {
          setIsrefrenceData(true);
          fetchData2();
        }}
      />
      {/* <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <strong>No Data Found</strong>
        </DialogTitle>

        <DialogContent>
          <Typography variant="subtitle2" fontSize={"17px"}>
            No data found for the selected date. How would you like to proceed?
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle2" fontSize={"14px"}>
                <strong>Add Data Manually:</strong> This option will provide an
                empty form where you can manually input the data. Most recent
                quarter data will be available as a reference.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2" fontSize={"14px"}>
                <strong>Retrieve Previous Quarter Data:</strong> This will
                retrieve the most recent available quarter's data and pre-fill
                the form for you. You can then update the data as needed.
              </Typography>
            </li>
          </ul>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              setIsrefrenceData(false);
              fetchData2();
            }}
            style={{
              margin: "5px",
              marginLeft: "20px",
              backgroundColor: "#007bff",
              borderRadius: "18px",
            }}
            variant="contained"
          >
            Add Data Manually
          </Button>
          <Button
            onClick={() => {
              setIsrefrenceData(true);
              fetchData2();
            }}
            style={{
              margin: "5px",
              marginLeft: "20px",
              backgroundColor: "#00C04B",
              borderRadius: "18px",
            }}
            variant="contained"
          >
            Retrieve Previous Quarter Data
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}
export default FundQtr;
