/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemText,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Paper,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import axiosInstance from "../../utils/axios/axios.instance";
import { IPortfolioQtr } from "../../interfaces/portfolioQtr.interface";
import { IFundStatic } from "../../interfaces/fundStatic.interface";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const FundOnboarding: React.FC = () => {
  const [fundName, setFundName] = useState("");
  const [companies, setCompanies] = useState<string[]>([]);
  const fundID = useRef<HTMLSelectElement>(null);
  const portfolioID = useRef<HTMLSelectElement>(null);
  const [fundNames, setFundNames] = useState<Array<{ [key: string]: string }>>(
    []
  );

  // Array of fund names
  const [fund_names, setFund_names] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [portfolio_names, setPortfolio_names] = useState<string[]>([]);
  const [portfolioNames, setPortfolioNames] = useState<
    Array<{ [key: string]: string }>
  >([]);

  const handleRemoveCompany = (index: number) => {
    const updatedCompanies = [...companies];
    updatedCompanies.splice(index, 1);
    setCompanies(updatedCompanies);
  };
  const handleAddCompany = () => {
    setCompanies([...companies, ""]);
  };
  const handleFundNameChange = (value: any) => {
    setFundName(value);
    if (companies.length === 0) {
      handleAddCompany();
    }
  };
  const handleSubmit = async () => {
    setLoading(true);

    const companyList = Array.isArray(companies) ? companies : [companies];
    const trimmedarray = companyList.filter((element) => element !== "");
    const form = new FormData();
    form.append("FundName", fundName);

    if (trimmedarray.length !== 0) {
      trimmedarray.forEach((company) => {
        form.append("companies", company);
      });
    }
    try {
      const response = await axiosInstance.post("/fund-addition", form);
      setFundName("");
      setCompanies([]);
      if (response.status !== 200) {
        toast.error("Error while updating Data.");
      } else {
        toast.success("Data updated successfully");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      getFundNames();
      getallPortfolioname();
    }
  };
  const getPortfolioNames = async (id: string) => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + id);
      const responseData = response.data.data;
      if (responseData.length !== 0) {
        responseData.sort(
          (
            a: { portfolio_companyName: string },
            b: { portfolio_companyName: string }
          ) => {
            return a.portfolio_companyName
              .toLowerCase()
              .localeCompare(b.portfolio_companyName.toLowerCase());
          }
        );
        const portfolioNames = responseData.map((portfolio: IPortfolioQtr) => {
          return {
            [portfolio.portfolio_companyName as string]: portfolio.company_ID,
          };
        });

        if (portfolioID.current) {
          portfolioID.current.value = Object.values(
            portfolioNames[0]
          )[0] as string;
          portfolioID.current.selectedIndex = 0;
        }
        setPortfolioNames(portfolioNames);
      } else {
        setPortfolioNames([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names");
      const responseData = response.data.data;
      const arrFundName: string[] = [];
      responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      const fundNames = responseData.map((fund: IFundStatic) => {
        arrFundName.push(fund.Fund_Name);
        return { [fund.Fund_Name]: fund.fund_ID };
      });
      setFund_names(arrFundName);
      setFundNames(fundNames);
      if (fundID.current) {
        fundID.current.value = Object.values(fundNames[0])[0] as string;
        getPortfolioNames(Object.values(fundNames[0])[0] as string);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getallPortfolioname = async () => {
    try {
      const response = await axiosInstance.get("/AllPortfolioCompanies");
      const responseData = response.data.data;
      const arrFundName: string[] = [];
      responseData.map((data: any) => {
        arrFundName.push(data.portfolio_companyName);
        return "";
      });
      setPortfolio_names(arrFundName);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFundNames();
    getallPortfolioname();
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Fund Onboarding" />
      <Typography variant="h3" gutterBottom display="inline">
        Fund Onboarding
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Fund Onbard</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Paper
            style={{
              padding: "16px",
            }}
          >
            <Typography variant="h3" gutterBottom display="inline">
              Existing Funds and Companies
            </Typography>
            <div style={{ paddingTop: "20px" }}>
              <div style={{ marginRight: "20px" }}>Funds:</div>
              <select
                ref={fundID}
                onChange={() => {
                  getPortfolioNames(fundID.current?.value ?? "F000001");
                }}
                style={{
                  maxWidth: "220px",
                  backgroundColor: "#f2f2f2", // Background color
                  color: "#333", // Text color
                  padding: "10px", // Padding
                  border: "1px solid #ccc", // Border
                  borderRadius: "5px", // Border radius
                  outline: "none", // Remove outline when selected
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                  width: "200px", // Width
                  cursor: "pointer", // Cursor style
                }}
              >
                {fundNames.map((fund, index) => {
                  const fundName = Object.keys(fund)[0];
                  const fundID = fund[fundName];
                  return (
                    <option key={index} value={fundID}>
                      {fundName}
                    </option>
                  );
                })}
              </select>
              {portfolioNames.length !== 0 ? (
                <select
                  ref={portfolioID}
                  onChange={() => {}}
                  style={{
                    backgroundColor: "#f2f2f2", // Background color
                    color: "#333", // Text color
                    padding: "10px", // Padding
                    border: "1px solid #ccc", // Border
                    borderRadius: "5px", // Border radius
                    outline: "none", // Remove outline when selected
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                    width: "200px", // Width
                    cursor: "pointer", // Cursor style
                    marginLeft: "40px",
                  }}
                >
                  {portfolioNames.map((portfolio, index) => {
                    const portfolioName = Object.keys(portfolio)[0];
                    const company_ID = portfolio[portfolioName];
                    return (
                      <option key={index} value={company_ID}>
                        {portfolioName}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <p>No Companies Available for the Fund</p>
              )}
            </div>
          </Paper>
          <Paper
            style={{
              padding: "25px",
              marginTop: "20px",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography variant="h3" gutterBottom display="inline">
                  Add new Fund
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  marginTop="15px"
                >
                  <Grid item>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Fund Name:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      freeSolo
                      options={fund_names}
                      inputValue={fundName}
                      onInputChange={(event, newInputValue) => {
                        setFundName(newInputValue);
                        handleFundNameChange(newInputValue);
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <ListItemText primary={option} />
                          <Divider />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Fund Name"
                          variant="outlined"
                          style={{
                            width: "50%",
                            textAlign: "justify",
                            background: "#fff",
                            borderColor: "#007bff",
                            borderWidth: "2px",
                            borderRadius: "4px",
                            marginBottom: "15px",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {fundName && (
                <Grid item xs={6}>
                  <Typography variant="h3" gutterBottom display="inline">
                    Add Companies
                  </Typography>
                  {companies.map((company, index) => (
                    <Grid item key={index}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ marginTop: "15px" }}
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {`Company Name ${index + 1}:`}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Autocomplete
                            freeSolo
                            options={portfolio_names}
                            inputValue={company}
                            onInputChange={(event, newInputValue) => {
                              const updatedCompanies = [...companies];
                              updatedCompanies[index] = newInputValue;
                              setCompanies(updatedCompanies);
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label={`Company Name ${index + 1}`}
                                  variant="outlined"
                                  style={{
                                    width: "50%",
                                    textAlign: "justify",
                                    background: "#fff",
                                    borderColor: "#007bff",
                                    borderWidth: "2px",
                                    borderRadius: "4px",
                                  }}
                                />
                                <IconButton
                                  onClick={() => handleRemoveCompany(index)}
                                  color="primary"
                                >
                                  <RemoveCircleOutlineIcon
                                    style={{
                                      fontSize: "25px",
                                    }}
                                  />
                                </IconButton>
                              </>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {index === companies.length - 1 && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginRight={"20%"}
                        >
                          <IconButton
                            onClick={handleAddCompany}
                            size="large"
                            color="primary"
                            aria-label="Add new company"
                          >
                            <AddCircleOutlineIcon
                              style={{
                                fontSize: "25px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>

            <Grid item>
              <Box display="flex" justifyContent="center">
                <Button
                  onClick={handleSubmit}
                  style={{
                    marginTop: "20px",
                    padding: "8px 30px",
                    backgroundColor: "#007bff",
                    borderRadius: "18px",
                  }}
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Add"
                  )}
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FundOnboarding;
