import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface ActionDialogProps {
  showDialog: boolean;
  onClose: () => void;
  action1onClick: () => void;
  action2onClick: () => void;
}

const ActionDialog: React.FC<ActionDialogProps> = ({
  showDialog,
  onClose,
  action1onClick,
  action2onClick,
}) => {
  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <strong>No Data Found</strong>
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle2" fontSize={"17px"}>
          No data found for the selected date. How would you like to proceed?
        </Typography>
        <ul>
          <li>
            <Typography variant="subtitle2" fontSize={"14px"}>
              <strong>Add Data Manually:</strong> This option will provide an
              empty form where you can manually input the data. Most recent
              quarter data will be available as a reference.
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle2" fontSize={"14px"}>
              <strong>Retrieve Previous Quarter Data:</strong> This will
              retrieve the most recent available quarter's data and pre-fill the
              form for you. You can then update the data as needed.
            </Typography>
          </li>
        </ul>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={action1onClick}
          style={{
            margin: "5px",
            marginLeft: "20px",
            backgroundColor: "#007bff",
            borderRadius: "18px",
          }}
          variant="contained"
        >
          Add Data Manually
        </Button>
        <Button
          onClick={action2onClick}
          style={{
            margin: "5px",
            marginLeft: "20px",
            backgroundColor: "#00C04B",
            borderRadius: "18px",
          }}
          variant="contained"
        >
          Retrieve Previous Quarter Data
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
