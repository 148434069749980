import React, {
  useState,
  ChangeEvent,
  FormEvent,
  CSSProperties,
  useEffect,
} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";

interface DynamicFormProps {
  fieldData: {
    [fieldName: string]: {
      type: string;
      name: string;
      defaultValue: string;
    };
  };
  fieldData2: {
    [fieldName: string]: {
      type: string;
      name: string;
      defaultValue: string;
    };
  };
  changedata: (
    formData: { [fieldName: string]: string | Boolean },
    updateType: "add"
  ) => void;
  staticData: boolean;
  date: string;
  isUpdate: boolean;
}

interface FormData {
  [fieldName: string]: string | Boolean;
}

const DynamicForm3: React.FC<DynamicFormProps> = ({
  fieldData,
  changedata,
  staticData,
  fieldData2,
  isUpdate,
}) => {
  useEffect(() => {
    const updatedFormState: FormData = {};
    setImageError(false);
    Object.keys(fieldData2).forEach((fieldName) => {
      updatedFormState[fieldName] = fieldData2[fieldName].defaultValue;
    });
    setFormData(updatedFormState);
  }, [fieldData2, staticData]);
  const [open, setOpen] = useState(false);
  const form = React.createRef<HTMLFormElement>();
  const initialFormState: FormData = {};
  const [update, setUpdate] = useState(false);
  // const handleSubmitForm = (event: any) => {
  //   event.preventDefault();
  //   setOpen(true); // Open dialog on form submit
  // };

  const handleConfirm = () => {
    // Perform the form submission task here
    setUpdate(false);
    // Call the onSubmit prop with the formData
    changedata(formData, "add");
    setChangedFields({});
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (staticData) {
    Object.keys(fieldData).forEach((fieldName) => {
      initialFormState[fieldName] = fieldData[fieldName].defaultValue || "";
    });
  } else {
    Object.keys(fieldData).forEach((fieldName) => {
      initialFormState[fieldName] = "";
    });
  }
  const handleFileChange = (event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFormData({
        ...formData,
        [event.target.name]: file, // Store the File object
      });
    }
  };
  const [imageError, setImageError] = useState(false);

  // Function to handle image load error
  const handleImageError = () => {
    setImageError(true);
  };

  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [changedFields, setChangedFields] = useState<FormData>({});
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      verticalAlign: "top",
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      verticalAlign: "text-top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "18px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "18px",
      cursor: "pointer",
    },
  };
  const handleChange2 = (name: string, value: Boolean) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setChangedFields({
      ...changedFields,
      [name]: value,
    });
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setChangedFields({
      ...changedFields,
      [name]: value,
    });
  };
  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpen(true); // Open dialog on form submit
    // e.preventDefault();
    // setUpdate(false);
    // changedata(formData, "add");
    // setChangedFields({});
  };
  const handleSubmitForm2 = () => {
    if (formData["portfolio_companyLogo"] != null) {
      const data = {
        portfolio_companyLogo: formData["portfolio_companyLogo"],
        ...changedFields,
      };
      changedata(data, "add");
      setUpdate(true);
      setChangedFields({});
    } else if (formData["fund_logo"] != null) {
      const data = {
        fund_logo: formData["fund_logo"],
        ...changedFields,
      };
      changedata(data, "add");
      setUpdate(true);
      setChangedFields({});
    } else {
      changedata(changedFields, "add");
      setUpdate(true);
      setChangedFields({});
    }
  };
  if (!staticData) {
    return (
      <div style={{ paddingLeft: "50px", paddingTop: "20px" }}>
        <form onSubmit={handleSubmitForm} ref={form}>
          <table>
            <tbody>
              {Object.keys(fieldData)
                .filter((key) => key !== "pdf_link")
                .map((fieldName, index, array) => (
                  <tr
                    key={fieldName}
                    style={{
                      paddingTop: "30px",
                      borderBottom:
                        index !== array.length - 1
                          ? "1px solid #cccccc"
                          : "none",
                    }}
                  >
                    <td style={{ width: "25%", ...styles.label }}>
                      {fieldData[fieldName].name}
                    </td>
                    <td style={{ verticalAlign: "top", width: "25%" }}>
                      <TextField
                        multiline
                        style={{
                          width: "100%",
                          marginBottom: "10px",
                        }}
                        minRows={1}
                        maxRows={6}
                        type={fieldData[fieldName].type}
                        id={fieldName}
                        // label={fieldData[fieldName].name}
                        name={fieldName}
                        value={(formData[fieldName] as string) || ""}
                        required={
                          !update &&
                          (fieldName === "fund_ID" ||
                            fieldName === "Fund_Name" ||
                            fieldName === "Reporting_Date" ||
                            fieldName === "portfolio_ID" ||
                            fieldName === "portfolio_companyName" ||
                            fieldName === "company_ID")
                        }
                        onInput={handleChange}
                        onChange={handleChange}
                        // style={styles.input || { verticalAlign: "text-top" }}
                        disabled={
                          fieldName === "Reporting_Date" ||
                          fieldName === "cc_Date" ||
                          fieldName === "distribution_date" ||
                          fieldName === "Reporting_Year" ||
                          fieldName === "Fund_Name" ||
                          fieldName === "portfolio_companyName" ||
                          fieldName === "LP_name"
                        }
                      />
                    </td>
                    <td style={styles.label2} width={"100%"}>
                      {fieldData[fieldName].defaultValue}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div style={styles.buttonContainer}>
            <button type="submit" style={styles.button}>
              {isUpdate ? "Update" : "Add"}
            </button>
          </div>
        </form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Submission</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to {isUpdate ? "update" : "add"} this data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirm} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div style={{ alignSelf: "center" }}>
        <div style={styles.buttonContainer}>
          <button
            type="button"
            style={styles.button2}
            onClick={handleSubmitForm2}
          >
            Update
          </button>
        </div>
        <form onSubmit={handleSubmitForm} ref={form}>
          <table>
            <tbody>
              {Object.keys(fieldData)
                .filter((key) => key !== "pdf_link")
                .map((fieldName) => (
                  <tr key={fieldName} style={{ paddingTop: "30px" }}>
                    <td style={styles.label}>{fieldName}</td>
                    {fieldData[fieldName].type === "file" ? (
                      <>
                        <td>
                          <input
                            key={fieldName}
                            type="file"
                            id={fieldName}
                            name={fieldName}
                            onChange={handleFileChange} // Separate handler for file type
                          />
                        </td>
                        <>
                          {fieldData[fieldName].defaultValue && !imageError ? (
                            <img
                              src={fieldData[fieldName].defaultValue as string}
                              width="100px"
                              height="100px"
                              onError={handleImageError}
                              alt="Dynamic Content"
                            />
                          ) : (
                            <p>No image available</p>
                          )}
                        </>
                      </>
                    ) : fieldData[fieldName].type === "Boolean" ? (
                      <td>
                        <input
                          type="checkbox"
                          id={fieldName}
                          name={fieldName}
                          value={(formData[fieldName] as string) || ""}
                          checked={!!formData[fieldName]} // Convert to boolean, ensuring checked reflects the true/false state
                          onChange={(e) => {
                            const value = e.target.checked as Boolean;

                            handleChange2(fieldName, value);
                          }}
                          style={styles.input}
                        />
                      </td>
                    ) : (
                      <td>
                        <input
                          type={fieldData[fieldName].type}
                          id={fieldName}
                          name={fieldName}
                          value={formData[fieldName] as string}
                          onChange={handleChange}
                          style={styles.input}
                          disabled={fieldName === "LP_name"}
                        />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </form>
      </div>
    );
  }
};

export default DynamicForm3;
